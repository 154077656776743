import React from 'react';

const Completion = () => {
    return (
        <section className="min-vh-100">
            <div className="container my-3">
                <h3
                >Hello man! This is confirmation that your payment has been successful. It's a temporary message set by developer, only for testing. Please don't mind. Thanks</h3>

            </div>
        </section>
    );
};

export default Completion;