import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Typography from '@mui/material/Typography';
import './blogV2.css';
import { useLocation, useParams } from 'react-router-dom';


const BlogDetailsV2 = () => {
    // const { id } = useParams();
    const id = localStorage.getItem("blogID");
    const [singleData, setSingleData] = useState([]);
    const [helmetKey, setHelmetKey] = useState(0);
    const props = useLocation();
    // console.log(props)

    // Function to change the helmetKey whenever needed to trigger a re-render
    const updateHelmet = () => {
        setHelmetKey(prevKey => prevKey + 1);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        const findID = props?.state.find(data => data?.id == id);
        setSingleData(() => findID);
        updateHelmet();
    }, [id, props?.state]);

    return (
        <section style={{ minHeight: '90vh' }}>
            <Helmet key={helmetKey}>
                <title>{singleData?.mT}</title>
                <meta name="description" content={singleData?.mD} />
                <link rel="icon" type="image/png" href={singleData?.img} />
                <link rel="apple-touch-icon" href={singleData?.img} />
            </Helmet>


            <div className='container mt-3 mb-5 cardFonts'>
                <div className='blogSingleCard mx-auto overflow-hidden'>
                    <h1 className='singleHeadTitle'>{singleData?.title}</h1>

                    <img src={singleData?.img} alt="" className='img-fluid' />
                    <Typography sx={{ fontSize: 16, color: '#282955a6' }} className='fw-bold pt-4' gutterBottom>
                        {singleData?.date} {singleData?.time}
                    </Typography>
                    <hr />

                    {
                        id == 1 && <div style={{ marginBottom: "50px", }}>
                            <div className="blog-main-content">
                                <h2>
                                    Digital credentials are electronic records in form of Digital
                                    certificates & documents that represent an individual's achievements,
                                    skills, or qualifications. They serve as a verifiable and secure way
                                    to showcase a person's educational accomplishments. These credentials
                                    can include academic degrees, certifications, badges, and other
                                    qualifications earned by a student.
                                </h2>
                            </div>
                            <h3 className="sub-heading-title">👉 Instant Verification:</h3>
                            <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog1.png"></img>
                            </div>
                            <div className="blog-main-content">
                                Managing and verifying paper credentials can be time-consuming and
                                resource-intensive. Digital credentials software automate this
                                process, reducing administrative workload and allowing staff to focus
                                on more strategic tasks.
                            </div>
                            <h3 className="sub-heading-title">👉 Cost-Efficiency:</h3>
                            <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog2.png"></img>
                            </div>

                            <div className="blog-main-content">
                                Issuing and managing digital credentials can be more cost-effective in
                                the long run compared to traditional paper-based systems. It reduces
                                printing and administrative costs associated with physical
                                certificates.
                            </div>
                            <h3 className="sub-heading-title">👉 Security and Authenticity:</h3>
                            <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog3.png"></img>
                            </div>
                            <div className="blog-main-content">
                                Digital credentials are stored on a blockchain or similar secure
                                platform, making them tamper-proof and highly resistant to fraud or
                                misrepresentation. This ensures that the credentials presented by a
                                student are genuine and unaltered.
                            </div>
                            <h3 className="sub-heading-title"> 👉 Global Accessibility:</h3>
                            <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog4.png"></img>
                            </div>
                            <div className="blog-main-content">
                                Digital credentials can be accessed and verified from anywhere in the
                                world, eliminating geographical barriers. This is particularly
                                beneficial for international students or professionals seeking
                                opportunities abroad.
                            </div>
                            <h3 className="sub-heading-title">
                                {" "}
                                👉 Enabling Stackable Credentials:
                            </h3>
                            <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog5.png"  ></img>
                            </div>
                            <div className="blog-main-content">
                                Digital credentials can be designed to stack or build upon one
                                another, allowing learners to accumulate a series of smaller
                                credentials that ultimately lead to a larger qualification or degree.
                                This supports flexible and modular learning pathways.
                            </div>
                        </div>
                    }

                    {
                        id == 2 && <div style={{ marginBottom: "50px" }}>
                            <div className="blog-main-content">
                                In today's digitally-driven landscape, ensuring the security and reliability of credentials is paramount. Conventional methods of certificate management often fall short, leaving room for vulnerabilities. This is where Zecurechain steps in, introducing a cutting-edge SaaS solution that revolutionizes how we handle digital credentials. Zecurechain recognizes the importance of safeguarding diverse digital credentials, from educational certificates to professional accreditations and critical documents. Leveraging the power of blockchain, our platform introduces an unprecedented level of security, transparency, and accessibility to credential management, culminating in a robust Blockchain Credential Verification process.
                            </div>
                            <h3 className="sub-heading-title">👉 Empowering India's Digital Credential Revolution:</h3>
                            {/* <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog1.png"></img>
                            </div> */}
                            <div className="blog-main-content">
                                Zecurechain's commitment to India's digital credential revolution goes beyond technology. With an emphasis on security and authenticity, our platform leverages blockchain to ensure that certificates issued and verified within the country maintain their integrity. This pivotal step forward establishes a more secure and trusted digital ecosystem, setting a new standard for credential management. Furthermore, Zecurechain's dedication to India's digital credential evolution extends to providing accessible and user-friendly tools for all stakeholders. Whether it's educational institutions, businesses, or individuals, our platform empowers them to seamlessly navigate the digital credential landscape with confidence.
                            </div>
                            <h3 className="sub-heading-title">👉 Easy Digital Certificate Creation and Issuance:</h3>
                            {/* <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog2.png"></img>
                            </div> */}

                            <div className="blog-main-content">
                                Zecurechain's streamlined approach to digital certificate creation and issuance is designed to make the process accessible to all. By harnessing blockchain technology, our platform ensures that certificates remain tamper-proof and unaltered throughout their lifecycle. This not only provides peace of mind for issuers but also establishes a higher level of trust in the certificates themselves. In addition, Zecurechain's intuitive interface and user-friendly features simplify the certificate creation process, making it efficient and hassle-free. This not only saves valuable time but also reduces the administrative burden on institutions and organizations.
                            </div>
                            <h3 className="sub-heading-title">👉 Effortless Verification of Digital Certificates:</h3>
                            {/* <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog3.png"></img>
                            </div> */}
                            <div className="blog-main-content">
                                Institutions and organizations using Zecurechain experience a seamless process for issuing and verifying digital certificates. This is not only a boon for administrative efficiency but also a testament to the robustness of the blockchain-powered verification system. Recipients can trust in the authenticity of their credentials, while issuers can have confidence in the validity of certificates they provide. Moreover, Zecurechain's verification process is designed for simplicity and accessibility. Users can effortlessly access and verify certificates using the power of blockchain, ensuring a smooth experience for all parties involved.
                            </div>
                            <h3 className="sub-heading-title"> 👉 A Trustworthy Educational Certificate Verification System:</h3>
                            {/* <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog4.png"></img>
                            </div> */}
                            <div className="blog-main-content">
                                Zecurechain understands the critical role that education plays in an individual's life. Our platform empowers educational institutions to establish a certificate verification system that not only safeguards the academic achievements of students but also elevates their global recognition and respect. This instills confidence in the credentials earned and opens doors to new opportunities. By leveraging blockchain technology, Zecurechain adds an extra layer of security and credibility to educational certificates. This ensures that academic accomplishments are recognized and valued on a global scale, contributing to the professional growth and success of individuals.

                            </div>
                            <h3 className="sub-heading-title">
                                {" "}
                                👉 Comprehensive Document Verification Capabilities:
                            </h3>
                            {/* <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog5.png"  ></img>
                            </div> */}
                            <div className="blog-main-content">
                                Zecurechain's capabilities extend far beyond certificates, encompassing the secure storage and verification of a wide range of documents. This comprehensive approach ensures that critical information remains intact and credible, providing a holistic solution for document management.
                                Moreover, Zecurechain's document verification system is designed with efficiency and accessibility in mind. Users can easily store and retrieve documents, knowing that they are protected by the robust security measures of blockchain technology. This not only enhances data integrity but also streamlines document management processes for businesses and individuals alike.
                                <br />
                                <br />
                                By leveraging blockchain, Zecurechain contributes to a more environmentally sustainable future by reducing the reliance on paper-based certificates. This aligns with a broader movement towards eco-conscious credential management practices.
                                Zecurechain represents a pivotal shift in how we approach digital credential management. With a strong emphasis on security, accessibility, and transparency, our platform is poised to redefine the standards for credential verification. Join us in this transformative journey towards a more secure and trusted digital credential ecosystem. Elevate your credentials with Zecurechain.
                            </div>
                        </div>
                    }
                    {
                        id == 3 && <div style={{ marginBottom: "50px" }}>
                            <div className="blog-main-content">
                                In today's digital age, the battle against document forgeries and ensuring the integrity of sensitive information is of paramount importance. Digital certificates and credentials have emerged as formidable tools in this fight, revolutionizing document security. In this article, we'll delve into how digital certificates are transforming document security and combating forgeries.
                            </div>
                            <h3 className="sub-heading-title">1. Understanding Digital Certificates</h3>
                            <h3 className="sub-heading-title mt-1">👉 Defining Digital Certificates:</h3>
                            {/* <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog1.png"></img>
                            </div> */}
                            <div className="blog-main-content">
                                A digital certificate is a cryptographic identifier that validates the identity of an entity in the digital realm. It serves as a digital signature, ensuring the authenticity of a document or message. This ensures that the information has not been tampered with during transit.
                            </div>
                            <h3 className="sub-heading-title">👉 Role in Document Security:</h3>
                            {/* <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog2.png"></img>
                            </div> */}

                            <div className="blog-main-content">
                                Digital certificates play a crucial role in document security by verifying the authenticity of the sender and ensuring the integrity of the document. This is particularly vital in sensitive transactions, legal proceedings, and secure communications.
                            </div>

                            <h3 className="sub-heading-title">2. Combatting Document Forgeries</h3>
                            <h3 className="sub-heading-title mt-1">Prevention of Alterations:</h3>
                            {/* <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog3.png"></img>
                            </div> */}
                            <div className="blog-main-content">
                                One of the key benefits of digital certificates is their ability to prevent document alterations. Once a document is digitally signed, any changes made to it will render the certificate invalid, immediately alerting parties to potential tampering.
                            </div>
                            <h3 className="sub-heading-title">👉 Protection Against Impersonation:</h3>
                            {/* <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog4.png"></img>
                            </div> */}
                            <div className="blog-main-content">
                                Digital certificates also safeguard against impersonation. By confirming the identity of the sender, they prevent malicious actors from posing as legitimate sources, reducing the risk of fraudulent activities.

                            </div>
                            <h3 className="sub-heading-title">
                                3. Digital Credentials in Document Security
                            </h3>
                            <h3 className="sub-heading-title mt-1">
                                👉 Enhancing Trust in Digital Transactions:
                            </h3>
                            {/* <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog5.png"  ></img>
                            </div> */}
                            <div className="blog-main-content">
                                Digital credentials, often based on digital certificates, instill trust in digital transactions. Whether it's e-commerce, financial transactions, or legal contracts, the presence of a valid digital certificate assures parties of the document's authenticity.
                            </div>
                            <h3 className="sub-heading-title ">
                                👉 Streamlining Authentication Processes:
                            </h3>
                            {/* <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog5.png"  ></img>
                            </div> */}
                            <div className="blog-main-content">
                                Digital credentials simplify authentication processes. Instead of relying on complex passwords or physical identification, a digital certificate provides a seamless and secure means of verifying identity.
                            </div>

                            <h3 className="sub-heading-title">
                                4. Implementing Digital Certificates
                            </h3>
                            <h3 className="sub-heading-title mt-1">
                                👉 Certificate Authorities (CAs):
                            </h3>
                            {/* <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog5.png"  ></img>
                            </div> */}
                            <div className="blog-main-content">
                                Certificate Authorities are trusted entities responsible for issuing and managing digital certificates. They play a pivotal role in establishing the credibility of digital certificates, ensuring they are recognized and accepted globally.
                            </div>
                            <h3 className="sub-heading-title">
                                👉 Integration into Workflows:
                            </h3>
                            {/* <div className="blog-detail-img">
                                <img alt="" src="/assets/images/blog5.png"  ></img>
                            </div> */}
                            <div className="blog-main-content">
                                Integrating digital certificates into existing workflows is crucial. This involves educating users on how to recognize and validate digital certificates, as well as implementing secure protocols for handling sensitive documents.
                                <br />
                                <br />
                                The adoption of digital certificates and credentials is a significant stride towards fortifying document security and combating forgeries. By leveraging cryptographic technology, businesses and individuals alike can operate in a digital landscape with confidence, knowing that their sensitive information is shielded from malicious intent. As the digital realm continues to evolve, the role of digital certificates will only become more pivotal in safeguarding our most valuable assets.
                            </div>
                        </div>
                    }

                </div>
            </div>

        </section>
    );
};

export default BlogDetailsV2;