import React, { useContext, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { UserAuthContext } from '../../Context/UserContext/UserContext';
import toast from 'react-hot-toast';

const Loader = () => {
    const { user, loadingUserProtected, setLoadingUserProtected } = useContext(UserAuthContext);

    useEffect(() => {
        setTimeout(() => {
            setLoadingUserProtected(false);
            // toast.error("Login required");
        }, 7000);
    }, [setLoadingUserProtected])

    return (
        <div className='d-flex justify-content-center align-items-start pb-4'>
            <Spinner animation="border" variant="primary" />
        </div>
    );
};

export default Loader;