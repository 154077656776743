import React from 'react';

const BottomHeader = () => {
    return (
        <div style={{ background: 'radial-gradient(50% 50% at 50% 50%, #2d37a6 0%, #1a1e65 100%)' }} className='pb-2'>
            <hr style={{ color: "#AEABAB", opacity: 1, }} className='my-0 container' />
            {/* <img src="/assets/linev2.png" alt="" className='img-fluid' /> */}
        </div>
    );
};

export default BottomHeader;