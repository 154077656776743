import { React, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./Pricing.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from "react-bootstrap";
import { useState } from "react";
import { UserAuthContext } from "../../Context/UserContext/UserContext";
import toast from "react-hot-toast";
import PayPopup from "./PayPopup";
import Freecurrencyapi from '@everapi/freecurrencyapi-js';

const Checkout = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserAuthContext);
  const props = useLocation();
  const [dropDownClicked, setDropDownVisibile] = useState(false);
  const [subscriptionType, setsubscriptionType] = useState("");
  const [checkoutPrice, setCheckoutPrice] = useState("");
  const [open, setOpen] = useState(false);
  const [price, setPrice] = useState("");

  const checkPrice = async () => {
    try {
      const freecurrencyapi = new Freecurrencyapi(process.env.REACT_APP_CONVERT_CURR_API_KEY);
      freecurrencyapi.latest({
        base_currency: 'USD',
        currencies: 'INR'
      }).then(res => {
        let amount = checkoutPrice * res.data.INR;
        setPrice(() => Math.floor(amount));
        // setPrice(() => amount);
        // console.log(res.data.INR);
        setOpen(true);
      })
        .catch((e) => {
          console.log(e);
          toast.dismiss();
          toast.error("Price exchange failed!");
          handleClose();
        })
      // await axios.get(`https://api.exchangeratesapi.io/v1/convert?access_key=${process.env.REACT_APP_CONVERT_API_KEY}&from=USD&to=INR&amount=${checkoutPrice}`)
      //   .then(res => {
      //     if (res.status == 200) {
      //       console.log(res.data);
      //       setPrice(() => res.data?.result);
      //     }
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     toast.dismiss();
      //     toast.error("Price exchange failed!");
      //     handleClose();
      //   })
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error("Price exchange failed!");
      handleClose();
    }
  }
  const handleClickOpen = async () => {

    await checkPrice();
  };

  const handleClose = (value) => {
    setOpen(false);
  };
  // console.log(props)
  const currencySymbol = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  useEffect(() => {
    setCheckoutPrice(
      props.state?.type == "Monthly"
        ? props.state?.price[0]
        : props.state?.price[1]
    );
    setsubscriptionType(props.state?.type);
    window.scrollTo(0, 0);
  }, [props.state?.price, props.state?.type]);


  const maxCer = {
    basic: (user?.types == "individual") ? 0 : 2,
    foundationCY: (user?.types == "individual") ? 0 : 1000,
    foundationCM: (user?.types == "individual") ? 0 : 50,
    intermediateCY: (user?.types == "individual") ? 0 : 1500,
    intermediateCM: (user?.types == "individual") ? 0 : 100,
    enterpriseCY: (user?.types == "individual") ? 0 : user?.type_of_subscription?.isEnterprise[0]?.cer,
    enterpriseCM: (user?.types == "individual") ? 0 : user?.type_of_subscription?.isEnterprise[0]?.cer,
  };
  const maxDoc = {
    basic: 2,
    foundationDY: 600,
    foundationDM: 50,
    intermediateDY: 1200,
    intermediateDM: 100,
    enterpriseDY: user?.type_of_subscription?.isEnterprise[0]?.docs,
    enterpriseDM: user?.type_of_subscription?.isEnterprise[0]?.docs
  };

  const paymentHandler = async (e) => {
    let ln = (props.state?.package)?.length;
    let lastChar = props.state?.package[ln - 2];
    let name;
    let pk = props.state?.name.charAt(0).toUpperCase() + props.state?.name.slice(1);
    if (lastChar == 'C') {
      name = "Certificate";
    }
    else if (lastChar == 'D') {
      name = "Document";
    }

    const checkIsExpire = (user?.type_of_subscription?.purchasedDate).find(data => (data?.packageName)?.slice(0, (data?.packageName).length - 2) === (props.state?.package)?.slice(0, (props.state?.package).length - 2));

    const isCountC = user?.type_of_subscription?.cer;
    const isCountD = user?.type_of_subscription?.docs;
    // console.log((user?.type_of_subscription?.name).length)
    // handleClickOpen();
    if ((user?.type_of_subscription?.name).includes("basic") && (user?.type_of_subscription?.name).length == 1) {
      handleClickOpen();
      // console.log("enter-1");
    }
    else {
      if (name == "Certificate") {
        if (!checkIsExpire?.isExpire || isCountC < maxCer[props.state?.package]) {
          // console.log(user);
          toast.dismiss();
          return toast.error("Contact Us To Add More");
        }
      }
      else if (name == "Document") {
        // console.log("enter-2");
        if (!checkIsExpire?.isExpire || isCountD < maxDoc[props.state?.package]) {
          // console.log(user);
          toast.dismiss();
          return toast.error("Contact Us To Add More");
        }
      }
    }
    handleClickOpen();
    // console.log("enter-3");
    // const API_URL = `${process.env.REACT_APP_BACKEND_URL}/api/v1/payment/`;
    // checkoutPrice, 
    // console.log("data", data); 
    // await axios.post(url, {
    //   amount: checkoutPrice * 100,
    //   currency: "INR",
    //   email: user?.email,
    //   packageName: props.state?.package
    // }, {
    //   headers: {
    //     "Content-Type": "Application/json",
    //     Authorization: `Bearer ${JSON.parse(token)}`,
    //   },
    // })
    //   .then(res => {
    //     if (res.status == 200) {

    //       toast.success(`Successfully Subscribed Zecurechain ${pk} Level ${name} For ${props.state?.type} Plan`);
    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //     toast.error("Something is wrong");
    //   })

  };
  function changeSubcriptionPlan(event) {
    setsubscriptionType(event.target.value);
    setCheckoutPrice(
      event.target.value == "Monthly"
        ? props.state?.price[0]
        : props.state?.price[1]
    );
    setDropDownVisibile(false);
  }
  return (
    <section className="container" style={{ minHeight: '90vh' }}>

      <div className='text-start mt-4'>
        <div className='d-flex justify-content-start align-items-center' style={{ gap: '5px' }}>
          <ArrowBackIcon style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
          <h4 className='dTitle' >Checkout</h4>
        </div>
        {/* <h4 className='dTitle mt-4'>Checkout</h4> */}
        <p style={{
          color: "#545454",
          textAlign: "start",
          fontFamily: 'Saira, sans-serif',
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal"
        }} className='mb-0'>Seamless Onboarding & Transparent Pricing</p>
      </div>

      <div className="checkout-box-style shadow">
        <div className="chk-title-style">{`Subscribe to ${props.state?.name}`}</div>
        <div>
          <div>
            <div className="pt-3 d-flex align-items-start  gap-2 justify-content-start flex-column ">
              {/* <div className="chk-price-style">
                {currencySymbol.format(checkoutPrice).split(".00")}
              </div>

              <div className="per-sub-style">
                {`per ${subscriptionType} + GST `}
                <img
                  className="img-fluid"
                  style={{ cursor: 'pointer' }}
                  width={20}
                  alt=""
                  src="/assets/images/dropdown.png"
                  onClick={() => setDropDownVisibile(!dropDownClicked)}
                />
              </div>

              <div className="chk-price-style">
                {currencySymbol.format(checkoutPrice).split(".00")}
              </div>
              <div className="per-sub-style">
                {`per ${subscriptionType} + GST `}
                <img
                  className="img-fluid"
                  style={{ cursor: 'pointer' }}
                  width={20}
                  alt=""
                  src="/assets/images/dropdown.png"
                  onClick={() => setDropDownVisibile(!dropDownClicked)}
                />
              </div> */}

              <div >
                <label className="d-flex align-items-center gap-2 justify-content-start">
                  <input
                    style={{ height: '20px', width: '20px', cursor: 'pointer' }}
                    type="radio"
                    value="Yearly"
                    checked={subscriptionType == "Yearly"}
                    onChange={changeSubcriptionPlan}
                  />
                  <span className="chk-price-style" style={{ paddingLeft: "0px" }}>
                    {currencySymbol.format(props.state?.price[1])}
                  </span>
                  <span>{" per Yearly + GST"}</span>
                </label>
              </div>
              <div >
                <label className="d-flex align-items-center gap-2 justify-content-start">

                  <input
                    style={{ height: '20px', width: '20px', cursor: 'pointer' }}
                    type="radio"
                    value="Monthly"
                    checked={subscriptionType == "Monthly"}
                    onChange={changeSubcriptionPlan}
                  />
                  <span className="chk-price-style" style={{ paddingLeft: "0px" }}>
                    {currencySymbol.format(props.state?.price[0])}
                  </span>
                  <span>{" per Monthly + GST"}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        {dropDownClicked == true ? (
          <div>
            <div
              className="radio per-sub-style radio-bg d-flex justify-content-start align-items-center"
              style={{ paddingLeft: "20px" }}
            >
              <div>
                <label className="d-flex align-items-center gap-2">

                  <input
                    style={{ height: '20px', width: '20px' }}
                    type="radio"
                    value="Monthly"
                    checked={subscriptionType == "Monthly"}
                    onChange={changeSubcriptionPlan}
                  />
                  <span className="chk-price-style" style={{ paddingLeft: "16px" }}>
                    {currencySymbol.format(props.state?.price[0])}
                  </span>
                  <span>{" per Monthly + GST"}</span>
                </label>
              </div>
            </div>
            <div
              className="radio per-sub-style radio-bg d-flex justify-content-start align-items-center"
              style={{ paddingLeft: "20px" }}
            >
              <div>
                <label className="d-flex align-items-center gap-2">
                  <input
                    style={{ height: '20px', width: '20px' }}
                    type="radio"
                    value="Yearly"
                    checked={subscriptionType == "Yearly"}
                    onChange={changeSubcriptionPlan}
                  />
                  <span className="chk-price-style" style={{ paddingLeft: "16px" }}>
                    {currencySymbol.format(props.state?.price[1])}
                  </span>
                  <span>{" per Yearly + GST"}</span>
                </label>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className="pt-2 sub-title-style">
          For business looking for the maximum comitative edge and expanding
          their reach
        </div>
        <div className="pt-3 d-flex justify-content-between align-items-center">
          <div>
            <h4>
              {props.state?.name
                .toLowerCase()
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </h4>
            <p>{`Billed ${subscriptionType}`}</p>
          </div>
          <h4>{currencySymbol.format(checkoutPrice)}</h4>
        </div>
        <div className="pt-3 d-flex justify-content-between align-items-center">
          <h4 className="chk-title-style">GST</h4>
          <h4>{currencySymbol.format(checkoutPrice)}</h4>
        </div>
        <div className="pt-3 d-flex justify-content-between align-items-center">
          <h4 className="chk-title-style">Total</h4>

          <h4>{currencySymbol.format(checkoutPrice)}</h4>
        </div>
        <div className="text-center">
          <Button
            variant="warning"
            className="px-4 py-3 rounded-pill"
            style={{ fontSize: "18px" }}
            type="submit"
            onClick={() => paymentHandler()}
          >
            Subscribe
          </Button>
        </div>
      </div>
      {open && <PayPopup open={open} setOpen={setOpen} handleClickOpen={handleClickOpen} handleClose={handleClose} checkoutPrice={price} email={user?.email} packageName={props.state?.package} usdPrice={Number(checkoutPrice)} />}
    </section>
  );
};
export default Checkout;
