import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './ShowQrDetails.css';
import toast from 'react-hot-toast';
import VerifiedIcon from '@mui/icons-material/Verified';
import Loader from '../../Components/Loader/Loader';

const ShowQrDetails = () => {
    const { hash } = useParams();
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [loading, setloading] = useState(true);

    useEffect(() => {

        const dataFetch = async () => {
            setloading(true);
            await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/data-ipHash/${hash}`)
                .then(res => {
                    if (res.status == 200) {
                        setData(() => res.data?.data);
                        // console.log(res.data?.data);
                    }
                })
                .catch((e) => {
                    console.log(e);
                    toast.dismiss();
                    toast.error("Not Found");
                    navigate("/");
                })
                .finally(() => {setloading(false)}
                );
        }
        dataFetch();
        window.scrollTo(0, 0);
    }, [hash]);
    const isNanHandleDate = (inputDate)=>{
        const [day, month, year] = inputDate.split("-");

        const rearrangedDate = `${month}-${day}-${year}`;
        const dateObject = new Date(rearrangedDate);
        // Extract the day, month, and year from the Date object
        const day2 = dateObject.getDate();
        const month2 = dateObject.getMonth() + 1;
        const year2 = dateObject.getFullYear();
        const formattedDateString = `${(day2 < 10 ? '0' : '') + day2}-${(month2 < 10 ? '0' : '') + month2}-${year2}`;
        return formattedDateString;
    }
    const formatDate = (inputDate) => {
        // Parse the input date string to a Date object
        const dateObject = new Date(inputDate);
        // Extract the day, month, and year from the Date object
        const day = dateObject.getDate();
        const month = dateObject.getMonth() + 1;
        const year = dateObject.getFullYear();
        if (isNaN(day) || isNaN(month) || isNaN(year)) {
            return isNanHandleDate(inputDate);
        }
        
        const formattedDateString = `${(day < 10 ? '0' : '') + day}-${(month < 10 ? '0' : '') + month}-${year}`;
        return formattedDateString;
    };

    const dateFormattor = (date) => {

        // Convert UTC date string to a JavaScript Date object
        const utcDate = new Date(date);

        // Get user's local time zone offset in minutes
        const userTimeZoneOffset = new Date().getTimezoneOffset();

        // Calculate local time by subtracting the time zone offset
        const localDate = new Date(utcDate.getTime() - userTimeZoneOffset * 60000);

        // Format the local date as "27 July 2023"
        const formattedDate = `${localDate.getDate()}/${localDate.getMonth()+1}/${localDate.getFullYear()}`;

        return formattedDate;

    }


    return (
        <section className=''>
            <div className='container mt-4'>
                <h1 className="titleOfCentral mt-3 mb-4">QR Details</h1>
                {loading && <Loader />}
                {
                    (!data?._id && !loading) && <div className='my-4 mx-auto text-center'><img src="/assets/images/explore.jpg" alt="" className='img-fluid w-50' /></div>
                }
                {
                    data?._id ? <>
                        {
                            data?.certificate ? <Card className='searchInputBar px-3 py-4 my-5'>
                                <CardContent>

                                    <div className='row gy-4 gx-2'>
                                        <div className='col-12 '>
                                            <div className="d-flex justify-content-start align-items-center flex-wrap" style={{ gap: "7px" }}><p className='handleTitleQRC'>Certificate Name:</p>
                                                <p className='handleDesQRC text-break'>{data?.name}</p></div>
                                        </div>
                                        <div className='col-12 '>
                                            <div className="d-flex justify-content-start align-items-center flex-wrap" style={{ gap: "7px" }}><p className='handleTitleQRC'>Receiver Email:</p>
                                                <p className='handleDesQRC text-break'>{data?.email}</p></div>
                                        </div>
                                        <div className='col-12 '>
                                            <div className="d-flex justify-content-start align-items-center flex-wrap" style={{ gap: "7px" }}><p className='handleTitleQRC'>Issuer Name:</p>
                                                <p className='handleDesQRC text-break'>{data?.issuerName}</p></div>
                                        </div>
                                        <div className='col-12 '>
                                            <div className="d-flex justify-content-start align-items-center flex-wrap" style={{ gap: "7px" }}><p className='handleTitleQRC'>Issuer Email:</p>
                                                <p className='handleDesQRC text-break'>{data?.issuer}</p><p><VerifiedIcon color='success' /></p></div>
                                        </div>
                                        <div className='col-12 '>
                                            <div className="d-flex justify-content-start align-items-center flex-wrap" style={{ gap: "7px" }}><p className='handleTitleQRC'>Course:</p>
                                                <p className='handleDesQRC text-break'>{data?.courseName}</p></div>
                                        </div>
                                        <div className='col-12 '>
                                            <div className="d-flex justify-content-start align-items-center flex-wrap" style={{ gap: "7px" }}><p className='handleTitleQRC'>Date:</p>
                                                <p className='handleDesQRC text-break'>{dateFormattor(data?.createdAt)}</p></div>
                                        </div>
                                        <div className='col-12 '>
                                            <div className="d-flex justify-content-start align-items-center flex-wrap" style={{ gap: "7px" }}><p className='handleTitleQRC'>Certificate Hash:</p>
                                                <p className='handleDesQRC text-break' >{data?.ipHash}</p><p><VerifiedIcon color='success' /></p></div>
                                        </div>
                                        <div className='col-12 '>
                                            <div className="d-flex justify-content-start align-items-center flex-wrap" style={{ gap: "7px" }}><p className='handleTitleQRC'>Transaction Hash:</p>
                                                <p className='handleDesQRC text-break' >{data?.hash}</p> <p><VerifiedIcon color='success' /></p></div>
                                        </div>
                                    </div>

                                </CardContent>
                            </Card> : <Card className='searchInputBar px-3 py-4 my-5'>
                                <CardContent>

                                    <div className='row gy-4 gx-2'>
                                        <div className='col-12 '>
                                            <div className="d-flex justify-content-start align-items-center flex-wrap" style={{ gap: "7px" }}><p className='handleTitleQRC'>Email:</p>
                                                <p className='handleDesQRC text-break'>{data?.email}</p></div>
                                        </div>
                                        <div className='col-12 '>
                                            <div className="d-flex justify-content-start align-items-center flex-wrap" style={{ gap: "7px" }}><p className='handleTitleQRC'>Name:</p>
                                                <p className='handleDesQRC text-break'>{data?.issuerName}</p><p><VerifiedIcon color='success' /></p></div>
                                        </div>
                                        <div className='col-12 '>
                                            <div className="d-flex justify-content-start align-items-center flex-wrap" style={{ gap: "7px" }}><p className='handleTitleQRC'>Date:</p>
                                                <p className='handleDesQRC text-break'>{data?.localDate}</p></div>
                                        </div>
                                        <div className='col-12 '>
                                            <div className="d-flex justify-content-start align-items-center flex-wrap" style={{ gap: "7px" }}><p className='handleTitleQRC'>Time:</p>
                                                <p className='handleDesQRC text-break'>{data?.localTime}</p></div>
                                        </div>
                                        <div className='col-12 '>
                                            <div className="d-flex justify-content-start align-items-center flex-wrap" style={{ gap: "7px" }}><p className='handleTitleQRC'>Doc Name:</p>
                                                <p className='handleDesQRC text-break'>{data?.fileName}</p></div>
                                        </div>
                                        <div className='col-12 '>
                                            <div className="d-flex justify-content-start align-items-center flex-wrap" style={{ gap: "7px" }}><p className='handleTitleQRC'>Doc Hash:</p>
                                                <p className='handleDesQRC text-break'>{data?.ipHash}</p><p><VerifiedIcon color='success' /></p></div>
                                        </div>
                                        <div className='col-12 '>
                                            <div className="d-flex justify-content-start align-items-center flex-wrap" style={{ gap: "7px" }}><p className='handleTitleQRC'>Blockchain Hash:</p>
                                                <p className='handleDesQRC text-break' >{data?.hash}</p><p><VerifiedIcon color='success' /></p></div>
                                        </div>
                                    </div>

                                </CardContent>
                            </Card>
                        }
                    </> : <></>
                }

            </div>
        </section>
    );
};

export default ShowQrDetails;