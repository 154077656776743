import React, { useState } from 'react';
import './blogV2.css';
import Card from 'react-bootstrap/Card';
import { Button } from 'react-bootstrap';
import CardM from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const BlogSingleCard = ({ data, dataFake }) => {
    const [isExpend, setIsExpend] = useState(false);
    const navigate = useNavigate();

    return (
        <div className='col-12 col-sm-12 col-md-6 col-lg-4 ' key={data?.id}>
            <CardM sx={{ width: '100%' }} className='shadow cardFonts'>
                <CardActionArea onClick={() => {
                    localStorage.setItem("blogID", data?.id);
                    navigate(`/blogs/${data?.url}`, { state: dataFake });
                }}>
                    <Card.Img variant="top" src={data?.img} />
                    <CardContent sx={{ padding: '10px' }}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {data?.date} <span className="time ps-2">{data?.time}</span>
                        </Typography>
                        <Typography gutterBottom variant="h5" component="div" className='fs-5' sx={{ color: 'black', fontWeight: 600 }}>
                            {data?.CardTitle}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ letterSpacing: '0' }}>
                            {!isExpend && <span >{(data?.description).slice(0, 200)} <span className='text-primary fw-bold' onClick={() => setIsExpend(!isExpend)}>...read more</span></span>}
                            {isExpend && <span>{data?.description} <span className='text-primary fw-bold' onClick={() => setIsExpend(!isExpend)}>...read less</span></span>}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </CardM>
        </div>
    );
};

export default BlogSingleCard;