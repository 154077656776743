import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";

function Payment({ checkoutPrice, email, packageName, onClose, usdPrice }) {
  // let checkoutPrice = 1.00;
  // console.log(checkoutPrice, typeof checkoutPrice);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY));
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  // const appearance = {
  //   theme: 'flat',
  //   variables: { colorPrimaryText: '#262626' }
  // };
  const options = {
    mode: 'payment',
    amount: checkoutPrice,
    currency: 'inr',
    payment_method_configuration: "pmc_1O6qEVSASLkOZlEF7TBHvPlj",
    // Customizable with appearance API.
    appearance: { appearance },
  };
  return (
    <div>
      <Elements stripe={stripePromise} options={
        options
      } >
        <CheckoutForm checkoutPrice={checkoutPrice} email={email} packageName={packageName} onClose={onClose} usdPrice={usdPrice} />
      </Elements>
    </div>
  );
}

export default Payment;
