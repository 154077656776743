import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import CryptoJS from 'crypto-js';
import { ethers } from "ethers";
import { Web3 } from "web3";
import initializeWeb3 from "./Assets/web3Utils";

export const BlockchainAuthContext = createContext();

const BlockchainAuthProvider = ({ children }) => {
    const [contract, setContract] = useState(null);
    const privateKey = process.env.REACT_APP_PRIVATE_KEY;

    useEffect(() => {
        const initWeb3 = async () => {
            const deployedContract = await initializeWeb3();
            setContract(() => deployedContract);
        };
        initWeb3();
    }, []);

    const uploadToIPFS = async (imageUrl) => {
        try {
            const responses = await axios.get(imageUrl, { responseType: 'arraybuffer' });
            const imageBlob = new Blob([responses.data], { type: 'image/jpeg' });

            // Convert Blob to a File object
            const file = new File([imageBlob], 'image.jpg', { type: 'image/jpeg' });

            const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';
            const formData = new FormData();
            formData.append('file', file);

            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    pinata_api_key: '82faefb649e8151a76f3',
                    pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET_API_KEY,
                },
            });

            return response.data.IpfsHash;
        } catch (error) {
            console.log('Error uploading to IPFS:', error);
            throw error;
        }
    };

    const uploadFilesToIPFS = async (selectedFiles) => {
        const ipfsHashes = [];
        for (const file of selectedFiles) {
            // console.log("file", file);
            try {

                const formData = new FormData();
                formData.append('file', file);
                // console.log(file);

                const options = {
                    headers: {
                        pinata_api_key: '82faefb649e8151a76f3',
                        pinata_secret_api_key: process.env.REACT_APP_PINATA_SECRET_API_KEY,
                    },
                };

                const response = await axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', formData, options);
                ipfsHashes.push(response.data.IpfsHash);
            } catch (error) {
                console.error('Error uploading file to IPFS:', error);
                return null;
            }
        }
        // console.log(ipfsHashes);
        return ipfsHashes;
    };

    const mintNFT = async (selectedFiles, recipientAddresses) => {
        // console.log(contract, privateKey, selectedFiles, recipientAddresses);
        if (!contract || !selectedFiles || !recipientAddresses) {
            console.error('Please initialize Web3, select files to upload, and enter recipient addresses.');
            return null;
        }

        try {
            const web3 = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_POLYGON_MUMBAI));
            const account = web3.eth.accounts.privateKeyToAccount(privateKey);
            web3.eth.accounts.wallet.add(account);
            web3.eth.defaultAccount = account.address;

            const toAddresses = recipientAddresses;
            // console.log(selectedFiles);
            const ipfsHashes = await uploadFilesToIPFS(selectedFiles);
            if (!ipfsHashes) {
                console.error('Error uploading files to IPFS. Aborting minting process.');
                return null;
            }

            const gasPrice = await web3.eth.getGasPrice();
            const nonce = await web3.eth.getTransactionCount(account.address);

            const contractMethod = contract.methods.mint(toAddresses, ipfsHashes);
            const encodedABI = contractMethod.encodeABI();

            const transaction = {
                from: account.address,
                to: contract.options.address,
                data: encodedABI,
                gasPrice,
                gas: web3.utils.toHex(3000000),
                nonce: web3.utils.toHex(nonce),
            };

            const signedTransaction = await web3.eth.accounts.signTransaction(transaction, privateKey);
            const rawTransaction = signedTransaction.rawTransaction;

            const receipt = await web3.eth.sendSignedTransaction(rawTransaction);
            // console.log('Transaction receipt:', receipt);

            const transactionHash = receipt.transactionHash;
            // console.log('Transaction hash:', transactionHash);

            // console.log('Tokens minted successfully.');

            return { ipfsHashes, transactionHash }
        } catch (error) {
            console.error('Error minting tokens:', error);
            // console.log(contract, privateKey, selectedFiles, recipientAddresses);
            return null;
        }
    };

    return (
        <BlockchainAuthContext.Provider
            value={{
                uploadFilesToIPFS,
                mintNFT,
                contract,
                privateKey
            }}
        >
            {children}
        </BlockchainAuthContext.Provider>
    );
};

export default BlockchainAuthProvider;