import * as React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import Payment from '../../Components/PaymentGateway/Payment';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';

const emails = ['username@gmail.com', 'user02@gmail.com'];

function SimpleDialog(props) {
    const { onClose, open, checkoutPrice, email, packageName, usdPrice } = props;

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = () => {
        onClose();
    };
    const currencySymbol = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "INR",
    });
    let changeWithDecimal = checkoutPrice * 100;
    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Pay {currencySymbol.format(checkoutPrice)}</DialogTitle>
            <DialogContent>
                <Payment checkoutPrice={changeWithDecimal} email={email} packageName={packageName} onClose={onClose} usdPrice={usdPrice} />
            </DialogContent>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

export default function PayPopup({ open, setOpen, handleClickOpen, handleClose, checkoutPrice, email, packageName, usdPrice }) {


    return (
        <React.Fragment>
            <SimpleDialog open={open} onClose={handleClose} checkoutPrice={checkoutPrice} email={email} packageName={packageName} usdPrice={usdPrice}
            />
        </React.Fragment>
    );
}