import React, { useState, useEffect, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import './Explorer.css';
import GifLoaderForExplorer from '../GifLoader/GifLoaderForExplorer';

const Explorer = () => {
    const [hash, setHash] = useState("");
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const searchAlgo = async () => {
        setLoading(true);

        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/data-ipHash/${hash}`)
            .then(res => {
                if (res.status == 200) {
                    // console.log(res.data);
                    setData(() => res.data?.data);
                }
                else {
                    toast.dismiss();
                    toast.error("Not Found");
                    setData([]);
                }

            })
            .catch((e) => {
                console.log(e);
                toast.dismiss();
                toast.error("Not Found");
                setData([]);
            })
            .finally((e) => {
                setLoading(false);
            })
    }
    // console.log(data);
    const handleKeyPress = async (e) => {
        // console.log('E', e);
        if (e.key === 'Enter') {
            // Call your function here
            await searchAlgo();
        }
    };
    return (
        <div>
            {loading ? <GifLoaderForExplorer /> :
                <div >
                    <div className='mx-auto'>
                        {/* <Form.Label htmlFor="basic-url">Your Hash</Form.Label> */}
                        <InputGroup className="mb-3 searchInputBar" style={{ height: "50px" }}>
                            <Form.Control
                                placeholder="Search by certificate hash only"
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                onKeyDown={handleKeyPress}
                                onChange={(e) => setHash(e.target.value)}
                            />
                            <Button variant="warning" id="button-addon2" style={{ boxShadow: 'none', width: '50px' }} onClick={() => searchAlgo()} >
                                <i class="fas fa-search" style={{ cursor: 'pointer' }}></i>
                            </Button>
                        </InputGroup>

                    </div>

                    {
                        !data?._id && <div className='my-4 mx-auto text-center'><img src="/assets/images/explore.jpg" alt="" className='img-fluid w-50' /></div>
                    }
                    {
                        data?._id ? <div className='my-4'>

                            {
                                data?.certificate ? <Card sx={{ maxWidth: 330, padding: 2, borderRadius: 5, background: '#E8E8E8' }} >
                                    <Typography variant="body2" className='d-flex justify-content-end align-items-center' style={{ marginTop: '-5px', paddingBottom: '10px' }}>
                                    </Typography>
                                    <CardActionArea onClick={() => {
                                        navigate(`/dashboard/dashboard-certificates-details/${data?._id}`);
                                        sessionStorage.setItem('selectedTab', '3');
                                    }} style={{ cursor: 'pointer' }}>
                                        <CardMedia
                                            component="img"
                                            height="auto"
                                            width="100%"
                                            image={data?.certificate}
                                            sx={{
                                                '&.MuiCardMedia-root': {
                                                    maxHeight: 225
                                                },
                                            }}
                                            alt="green iguana"
                                        />
                                        <CardContent className='mt-2'>

                                            <Typography variant="body2" className='d-flex justify-content-between align-items-center'>
                                                <p className='cardSubTitleIssue'>Issued by :</p>
                                                <p className='cardSubInfoIssue'>{data?.issuerName ? data?.issuerName : data?.issuer}</p>
                                            </Typography>
                                            <Typography variant="body2" className='d-flex justify-content-between align-items-center'>
                                                <p className='cardSubTitleIssue mb-0'>Received by :</p>
                                                <p className='cardSubInfoIssue mb-0'>{data?.name ? data?.name : data?.email}</p>
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card> :
                                    <div style={{ maxWidth: 330, cursor: 'pointer' }} onClick={() => {
                                        navigate(`/dashboard/dashboard-document-details/${data?._id}`);
                                        sessionStorage.setItem('selectedTab', '3');
                                    }}>
                                        <div className='box-image2 mt-3' style={{ height: '300px' }}>
                                            <img src="/assets/images/docsFile.png" alt="" className='img-fluid' width={150} />
                                            <p>{data?.fileName} ({data?.fileSize})</p>
                                        </div>
                                    </div>
                            }




                        </div> : <></>
                    }

                </div>
            }
        </div>
    );
};

export default Explorer;