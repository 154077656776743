import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import CryptoJS from 'crypto-js';

export const UserAuthContext = createContext();

const UserAuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [allUser, setAllUser] = useState(null);
    const [requestLoading, setRequestLoading] = useState(false);
    const [currentAccount, setCurrentAccount] = useState(null);
    const [userRefetch, setUserRefetch] = useState(false);
    const [loadingUser, setLoadingUser] = useState(false);
    const [loadingUserProtected, setLoadingUserProtected] = useState(true);
    const [secret, setSecret] = useState("");
    const [emailPro, setEmailPro] = useState("");
    const [certificate, setCertificate] = useState([]);
    const [docs, setDocs] = useState([]);
    const [restrict, setRestrict] = useState(true);
    const [combinedData, setCombinedData] = useState([]);
    const [lock, setLock] = useState(false);
    const [files, setFiles] = useState({ origin: null, show: null });
    const [combinedLoader, setCombinedLoader] = useState(false);

    const [folderStructure, setFolderStructure] = useState({ isInFolder: false, folder: null, isSub: false, folderId: null });
    const navigate = useNavigate();
    useEffect(() => {
        setSecret(() => process.env.REACT_APP_MY_SECRET_KYE);
    }, []);
    useEffect(() => {
        if (requestLoading) {
            const wrapper = document.createElement("div");
            wrapper.innerHTML = `<p></p><div class="loaders"></div> <p class="wait"><b>Please wait, don't exit screen...<b></p> `;
            swal({
                content: wrapper,
                button: false,
                className: "modal_class_success",
            });
        }
    }, [requestLoading]);


    const signUpUserIssuer = async (email, org_individual_Name, org_type, phone, password, confirmPassword, web_social_URL, file, propState) => {
        if (password !== confirmPassword) {
            toast.dismiss();
            toast.error("Passwords didn't matched!");
            return;
        }
        if (!email || !org_individual_Name || !org_type || !phone || !password || !web_social_URL || !file) {
            toast.dismiss();
            toast.error("Please fillUp all details!");
            return;
        }
        else {
            setLoadingUser(true);

            const dataForm = new FormData();
            dataForm.append("email", email);
            dataForm.append("org_individual_Name", org_individual_Name);
            dataForm.append("org_type", org_type);
            dataForm.append("phone", phone);
            dataForm.append("password", password);
            dataForm.append("web_social_URL", web_social_URL);
            dataForm.append("proveFile", file);
            dataForm.append("types", "issuer");

            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/`, dataForm, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
                .then(async res => {
                    if (res.status == 200) {
                        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/sending-otp?type=signup`, { email })
                            .then(res => {
                                if (res.status == 200) {
                                    setEmailPro(email);
                                    if (propState?.state != null) {

                                        navigate(`/otp/${res.data.token}`, { state: propState?.state });
                                    }
                                    else {
                                        navigate(`/otp/${res.data.token}`);
                                    }
                                    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(res.data.token), process.env.REACT_APP_MY_SECRET_KYE).toString();
                                    setRestrict(false);
                                    localStorage.setItem('userToken', encryptedData);
                                }
                                else {
                                    toast.error("Something is wrong!");
                                }
                            })
                            .catch((e) => {
                                console.log(e);
                                toast.error(e?.response?.data?.message);
                            });
                    }
                    else {
                        toast.error("Something is wrong!");
                    }
                })
                .catch((e) => {
                    console.log(e);
                    toast.error(e?.response?.data?.message);
                });
            setLoadingUser(false);
        }
    }
    const signUpUserIndi = async (email, phone, password, confirmPassword, propState) => {
        if (password !== confirmPassword) {
            toast.error("Passwords didn't matched!");
            return;
        }
        if (!email || !phone || !password) {
            toast.error("Please fillUp all details!");
            return;
        }
        else {
            setLoadingUser(true);
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/`, { email, phone, password, types: "individual" }, {
                headers: {
                    "Content-Type": "Application/json",
                }
            })
                .then(async res => {
                    if (res.status == 200) {
                        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/sending-otp?type=signup`, { email })
                            .then(res => {
                                if (res.status == 200) {
                                    setEmailPro(email);
                                    if (propState?.state != null) {

                                        navigate(`/otp/${(res.data.token).slice(0, 5)}`, { state: propState?.state });
                                    }
                                    else {
                                        navigate(`/otp/${(res.data.token).slice(0, 5)}`);
                                    }
                                    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(res.data.token), process.env.REACT_APP_MY_SECRET_KYE).toString();
                                    setRestrict(false);
                                    localStorage.setItem('userToken', encryptedData);
                                }
                                else {
                                    toast.error("Something is wrong!");
                                }
                            })
                            .catch((e) => {
                                console.log(e);
                                toast.error(e?.response?.data?.message);
                            });
                    }
                    else {
                        toast.error("Something is wrong!");
                    }
                })
                .catch((e) => {
                    console.log(e);
                    toast.error(e?.response?.data?.message);

                });
            setLoadingUser(false);
        }
    }

    const resendOTP = async (email, x, propState) => {
        setLoadingUser(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/sending-otp?type=${x}`, { email })
            .then(res => {
                if (res.status == 200) {
                    setEmailPro(email);
                    // console.log("log", propState);
                    if (propState?.state != null) {
                        // console.log("OTP ENTER");
                        navigate(`/otp/${(res.data.token).slice(0, 5)}`, { state: propState?.state });
                    }
                    else {
                        navigate(`/otp/${(res.data.token).slice(0, 5)}`);

                    }
                    toast.success("OTP sent! Please check your email.");
                    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(res.data.token), process.env.REACT_APP_MY_SECRET_KYE).toString();
                    setRestrict(false);
                    localStorage.setItem('userToken', encryptedData);
                }
                else {
                    toast.error("Something is wrong!");
                }
            })
            .catch((e) => {
                console.log(e);
            });
        setLoadingUser(false);
    }

    const verifyingOTP = async (otp, propState) => {

        const token = CryptoJS.AES.decrypt(localStorage.getItem("userToken"), process.env.REACT_APP_MY_SECRET_KYE).toString(CryptoJS.enc.Utf8);
        setLoadingUser(true);

        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/verify-otp`, { otp: otp }, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${JSON.parse(token)}`,
            }
        })
            .then(async res => {
                if (res.status == 200 && res.data?.isVerified === true) {
                    toast.success("OTP verified!");
                    setUser(() => res.data.user);
                    setRestrict(true);
                    // if (res.data?.lock) {

                    //     setLock(() => res.data?.lock);
                    // }
                    // setDocs(() => res.data?.doc);
                    // setCertificate(() => res.data?.cer);
                    // sessionStorage.setItem('selectedTab', '0');
                    // // console.log("verify", propState);
                    // const mergedData = [...res.data?.cer, ...res.data?.doc];
                    // // console.log(mergedData)
                    // // Sort the combined data by createdAt or updatedAt in descending order
                    // const shortIssue = mergedData.sort((a, b) => new Date(b.createdAt || b.updatedAt) - new Date(a.createdAt || a.updatedAt));
                    // // console.log(shortIssue);
                    // // Set the combined and sorted data in state
                    // setCombinedData(() => shortIssue.slice(0, 10));
                    if (propState?.state != null) {
                        // console.log("enter final");
                        navigate(`/checkout/${propState?.state?.name}`, { state: propState?.state });
                    }
                    else {
                        navigate("/dashboard");
                    }
                    await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/all`, {
                        headers: {
                            authorization: `Bearer ${JSON.parse(token)}`
                        }
                    })
                        .then(res => {
                            if (res.status == 200) {
                                // console.log("user");
                                setAllUser(() => res.data.data);

                            }
                        })
                }
                else {
                    toast.error(`${res.data.message}`);
                }
            })
            .catch((e) => {
                console.log(e);
                toast.error(`${e.response?.data?.message}`);
            })
            .finally(() => { setLoadingUser(false); });
        setLoadingUser(false);
    }

    const loginUser = async (email, password, propState) => {
        setLoadingUser(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/login-user`, { email, password }, {
            headers: {
                "Content-Type": "Application/json"
            }
        })
            .then(res => {
                if (res.status == 200) {
                    // console.log("log", propState);
                    if (propState?.state != null) {
                        // console.log("Enter");
                        resendOTP(email, 'signin', propState);
                    }
                    else {
                        resendOTP(email, 'signin', propState);

                    }
                    // navigate(`/otp/${res.data.token}`);
                    // toast.success("Logged in!");
                    // const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(res.data.token), secret).toString();
                    // localStorage.setItem('userToken', encryptedData);
                    // setUser(res.data.user);
                    // navigate("/dashboard");

                }
                else {
                    toast.error(`${res.data?.message}`);
                    setLoadingUser(false);
                }
            })
            .catch((e) => {
                console.log(e);
                toast.error(`${e?.response?.data}`);
                setLoadingUser(false);
            });

    }

    const LogOut = () => {
        setUser(null);
        localStorage.removeItem("userToken");
        toast.success("Logged out!");
        navigate("/");
        setUserRefetch(!userRefetch);
    }

    const sendResetPasswordMail = async (email) => {
        setLoadingUser(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/send-reset-password-link/`, { email }, {
            headers: {
                "Content-Type": "Application/json"
            }
        })
            .then(res => {
                if (res.status == 200) {

                    toast.success(`${res.data.message}`);

                }
                else {
                    toast.error(`${res.data.message}`);
                }
            })
            .catch((e) => {
                console.log(e);
                toast.error(`${e.response.data}`);
            });

        setLoadingUser(false);
    }

    const forgotPassword = async (newPassword, token) => {
        setLoadingUser(true);
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/forget-password`, { newPassword }, {
            headers: {
                "Content-Type": "Application/json",
                authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                if (res.status == 200) {
                    toast.success("Password has been updated successfully!");
                    navigate("/login");
                }
                else {
                    toast.error(`${res.data.message}`);
                }
            })
            .catch((e) => {
                console.log(e);
                toast.error(`${e.response.data.message}`);
            });
        setLoadingUser(false);
    }

    const checkPassword = async (email, password) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/user/check-password`,
                { email, password },
                {
                    headers: {
                        "Content-Type": "Application/json"
                    }
                }
            );

            if (response.status === 200) {
                return true;
            } else {
                toast.error(`${response.data?.message}`);
                return false;
            }
        } catch (error) {
            console.log(error);
            toast.error(`${error?.response?.data}`);
            return false;
        }
    };



    const dataFetchCertificate = async (email, userProp) => {
        const token = CryptoJS.AES.decrypt(localStorage.getItem("userToken"), process.env.REACT_APP_MY_SECRET_KYE).toString(CryptoJS.enc.Utf8);
        let checkType = (userProp?.types === "issuer" && 'issuer') || (userProp?.types === "individual" && 'receiver');
        // console.log(checkType);
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/certificate/by-email-${checkType}/${email}`, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${JSON.parse(token)}`,
            }
        })
            .then(res => {
                // console.log(res.data, 'cer');
                if (res.status == 200) {
                    // console.log("data fetch");
                    setCertificate(() => res.data?.data);
                    const findLock = res.data?.data.find(item => item?.isLock === true);
                    if (findLock) {
                        setLock(() => true);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {

            });
    }
    const dataFetchDocument = async (email) => {
        const token = CryptoJS.AES.decrypt(localStorage.getItem("userToken"), process.env.REACT_APP_MY_SECRET_KYE).toString(CryptoJS.enc.Utf8);
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/document/get-email/${email}`, {
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${JSON.parse(token)}`,
            }
        })
            .then(res => {
                // console.log(res.data, 'cer');
                if (res.status == 200) {
                    const findLock = res.data?.data.find(item => item?.isLock === true);
                    if (findLock) {
                        setLock(() => true);
                    }
                    setDocs(() => res.data?.data);
                }
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {

            });
    }


    const recentShowing = async (emailProps, userPros) => {
        if (!certificate || !docs) {
            // await dataFetchCertificate(emailProps, userPros);
            // await dataFetchDocument(emailProps);
            const token = CryptoJS.AES.decrypt(localStorage.getItem("userToken"), process.env.REACT_APP_MY_SECRET_KYE).toString(CryptoJS.enc.Utf8);
            let checkType = (userPros?.types === "issuer" && 'issuer') || (userPros?.types === "individual" && 'receiver');
            // console.log(checkType);
            const cerRes = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/certificate/by-email-${checkType}/${emailProps}`, {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${JSON.parse(token)}`,
                }
            });
            const docRes = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/document/get-email/${emailProps}`, {
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${JSON.parse(token)}`,
                }
            });
            const mergedData = [...cerRes?.data, ...docRes?.data];
            // console.log("mer", mergedData)
            // Sort the combined data by createdAt or updatedAt in descending order
            const shortIssue = mergedData.sort((a, b) => new Date(b.createdAt || b.updatedAt) - new Date(a.createdAt || a.updatedAt));
            // console.log(shortIssue);
            // Set the combined and sorted data in state
            setCombinedData(() => shortIssue.slice(0, 10));

        }
        else {
            const mergedData = [...certificate, ...docs];
            // console.log("mer", mergedData)
            // Sort the combined data by createdAt or updatedAt in descending order
            const shortIssue = mergedData.sort((a, b) => new Date(b.createdAt || b.updatedAt) - new Date(a.createdAt || a.updatedAt));
            // console.log(shortIssue);
            // Set the combined and sorted data in state
            setCombinedData(() => shortIssue.slice(0, 10));
        }
    }

    // useEffect(() => {
    //     const catchTheUser = async () => {
    //         // console.log("Start");
    //         setLoadingUserProtected(true);
    //         const token = CryptoJS.AES.decrypt(localStorage.getItem("userToken"),process.env.REACT_APP_MY_SECRET_KYE).toString(CryptoJS.enc.Utf8);
    //         const parsedToken = JSON.parse(token);
    //         const finalToken = `Bearer ${parsedToken}`;
    //         if (finalToken) {
    //             await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/current-user`, {
    //                 headers: {
    //                     authorization: finalToken
    //                 }
    //             })
    //                 .then(async res => {
    //                     if (res.status == 200 && res.data?.user?.isVerified == true) {
    //                         // console.log("from here")
    //                         setUser(() => res.data.user);
    //                         await dataFetchCertificate(res.data?.user?.email, res.data.user);
    //                         await dataFetchDocument(res.data?.user?.email);
    //                         await recentShowing(res.data?.user?.email, res.data.user);

    //                     }
    //                 })
    //                 .catch(e => console.log(e))
    //                 .finally(() => {
    //                     setLoadingUserProtected(false);
    //                     setCombinedLoader(()=>false);
    //                     // console.log("End");
    //                 })
    //         }

    //     }
    //     const catchUsers = async () => {
    //         setLoadingUserProtected(true);
    //         const token = CryptoJS.AES.decrypt(localStorage.getItem("userToken"),process.env.REACT_APP_MY_SECRET_KYE).toString(CryptoJS.enc.Utf8);
    //         const parsedToken = JSON.parse(token);
    //         const finalToken = `Bearer ${parsedToken}`;
    //         if (finalToken) {
    //             await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/all`, {
    //                 headers: {
    //                     authorization: finalToken
    //                 }
    //             })
    //                 .then(res => {
    //                     if (res.status == 200) {
    //                         // console.log("user");
    //                         setAllUser(() => res.data.data);

    //                     }
    //                 })
    //                 .catch(e => console.log(e))
    //                 .finally(() => {
    //                     setLoadingUserProtected(false);
    //                 })
    //         }
    //     }

    //     if (localStorage.getItem("userToken") && restrict) {
    //         setCombinedLoader(()=>true);
    //         catchTheUser();
    //         catchUsers();
    //     }
    // }, [localStorage.getItem("userToken"), secret, userRefetch, restrict]);
    const fetchData = async (url, headers) => {
        try {
            const response = await axios.get(url, { headers });
            if (response.status === 200) {
                const findLock = response.data?.data.find(item => item?.isLock === true);
                if (findLock) {
                    setLock(true);
                }
                return response.data?.data || [];
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            return [];
        }
    };

    const fetchUserData = async (email, userProp) => {
        const token = CryptoJS.AES.decrypt(localStorage.getItem("userToken"), process.env.REACT_APP_MY_SECRET_KYE).toString(CryptoJS.enc.Utf8);
        const checkType = (userProp?.types === "issuer" && 'issuer') || (userProp?.types === "individual" && 'receiver');

        const headers = {
            'Content-Type': 'application/json',
            authorization: `Bearer ${JSON.parse(token)}`,
        };

        const certificateUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/certificate/by-email-${checkType}/${email}`;
        const documentUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/document/get-email/${email}`;

        const [certificateData, documentData] = await Promise.all([
            fetchData(certificateUrl, headers),
            fetchData(documentUrl, headers),
        ]);

        const mergedData = [...certificateData, ...documentData];
        const sortedData = mergedData.sort((a, b) => new Date(b.createdAt || b.updatedAt) - new Date(a.createdAt || a.updatedAt));

        return sortedData.slice(0, 10);
    };

    const fetchDataForUser = async () => {
        try {
            setLoadingUserProtected(true);
            const token = CryptoJS.AES.decrypt(localStorage.getItem("userToken"), process.env.REACT_APP_MY_SECRET_KYE).toString(CryptoJS.enc.Utf8);
            const parsedToken = JSON.parse(token);
            const finalToken = `Bearer ${parsedToken}`;

            if (finalToken) {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/current-user`, {
                    headers: {
                        authorization: finalToken
                    }
                });

                const allUserGet = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/all`, {
                    headers: {
                        authorization: finalToken
                    }
                });
                if (allUserGet.status == 200) {
                    // console.log(allUserGet.data)
                    setAllUser(() => allUserGet.data.data);
                }
                if (response.status === 200 && response.data?.user?.isVerified) {
                    setUser(response.data.user);
                    const combinedData = await fetchUserData(response.data?.user?.email, response.data.user);
                    setCombinedData(combinedData);
                    setCombinedLoader(false);
                    await dataFetchCertificate(response.data?.user?.email, response.data.user);
                    await dataFetchDocument(response.data?.user?.email);
                }
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        } finally {
            setLoadingUserProtected(false);
            setCombinedLoader(false);
        }
    };

    useEffect(() => {
        if (localStorage.getItem("userToken") && restrict) {
            setCombinedLoader(true);
            fetchDataForUser();
            // ... other operations if needed
        }
    }, [localStorage.getItem("userToken"), secret, userRefetch, restrict]);


    // useEffect(() => {
    //     const catchTheUser = async () => {
    //         const token = CryptoJS.AES.decrypt(localStorage.getItem("userToken"), secret).toString(CryptoJS.enc.Utf8);
    //         await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/current-user`, {
    //             headers: {
    //                 authorization: `Bearer ${JSON.parse(token)}`
    //             }
    //         })
    //             .then(res => {
    //                 if (res.status == 200) {
    //                     setUser(() => res.data.user);
    //                 }
    //             })
    //             .catch(e => console.log(e));
    //     }
    //     if (!user) {
    //         catchTheUser();
    //         dataFetchCertificate();
    //     }

    // }, [user, dataFetchCertificate, secret]);
    // console.log("structure", folderStructure);

    const cerTemp = [
        { id: "1", name: "cer1", temp: "/assets/images/ct1.png", cover: "/assets/images/ctd1.png" },
        { id: "2", name: "cer2", temp: "/assets/images/ct2.png", cover: "/assets/images/ctd2.png" },
        { id: "3", name: "cer3", temp: "/assets/images/ct3.png", cover: "/assets/images/ctd3.png" },
        { id: "4", name: "cer4", temp: "/assets/images/ct4.png", cover: "/assets/images/ctd4.png" },
        { id: "5", name: "cer5", temp: "/assets/images/ct5.png", cover: "/assets/images/ctd5.png" },
        { id: "6", name: "cer6", temp: "/assets/images/ct6.png", cover: "/assets/images/ctd6.png" },
        { id: "7", name: "cer7", temp: "/assets/images/ct7.png", cover: "/assets/images/ctd7.png", divSpecial: "/assets/images/ct7_div.png" },
        { id: "8", name: "cer8", temp: "/assets/images/ct8.png", cover: "/assets/images/ctd8.png" },
        { id: "9", name: "cer9", temp: "/assets/images/ct9.png", cover: "/assets/images/ctd9.png" },
        { id: "10", name: "cer10", temp: "/assets/images/ct10.png", cover: "/assets/images/ctd10.png" },
        user?.email === `${process.env.REACT_APP_CLIENT_1}` && { id: "11", name: "cer11", temp: "/assets/images/ct8_v2.png", cover: "/assets/images/ct8.png" },
        (user?.email === `${process.env.REACT_APP_CLIENT_4}` || user?.email === `${process.env.REACT_APP_CLIENT_1}`) && { id: "12", name: "cer12", temp: "/assets/images/ct12.png", cover: "/assets/images/ctd12.png", divSpecial: "/assets/images/ct12_div.png" },
        user?.email === `${process.env.REACT_APP_CLIENT_3}` && { id: "13", name: "cer13", temp: "/assets/images/ct13.png", cover: "/assets/images/ctd13.png", divSpecial: "" },
        (user?.email === `${process.env.REACT_APP_CLIENT_1}` || user?.email === `${process.env.REACT_APP_CLIENT_2}`) && { id: "14", name: "cer14", temp: "/assets/images/ct14.png", cover: "/assets/images/ctd14.png", divSpecial: "" },
        (user?.email === `${process.env.REACT_APP_CLIENT_1}` || user?.email=== `${process.env.REACT_APP_CLIENT_5}`) && { id: "15", name: "cer15", temp: "/assets/images/ct15.png", cover: "/assets/images/ctd15.png", divSpecial: "" },
        (user?.email === `${process.env.REACT_APP_CLIENT_1}` || user?.email=== `${process.env.REACT_APP_CLIENT_6}`) && { id: "16", name: "cer16", temp: "/assets/images/ct16.png", cover: "/assets/images/ctd16.png", divSpecial: "/assets/images/ct16_div.png" },
    ]

    const cerComingSoon = [
        { id: "1", name: "cer1", temp: "/assets/images/certiComingSoon1.png" },
        { id: "2", name: "cer2", temp: "/assets/images/certiComingSoon2.png" },
        { id: "3", name: "cer3", temp: "/assets/images/certiComingSoon3.png" },
    ]

    return (
        <UserAuthContext.Provider
            value={{
                secret,
                requestLoading,
                setRequestLoading,
                signUpUserIndi,
                signUpUserIssuer,
                verifyingOTP,
                loginUser,
                user,
                combinedLoader,
                setCombinedLoader,
                setUserRefetch,
                userRefetch,
                setLoadingUser,
                loadingUser,
                emailPro,
                resendOTP,
                LogOut,
                forgotPassword,
                sendResetPasswordMail,
                allUser,
                checkPassword,
                certificate,
                setCertificate,
                setDocs,
                docs,
                dataFetchDocument,
                dataFetchCertificate,
                folderStructure,
                setFolderStructure,
                cerTemp,
                cerComingSoon,
                setLoadingUserProtected,
                loadingUserProtected,
                restrict,
                recentShowing,
                combinedData,
                lock,
                files,
                setFiles
            }}
        >
            {children}
        </UserAuthContext.Provider>
    );
};

export default UserAuthProvider;
