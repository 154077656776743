import React, { useEffect } from 'react';
import Explorer from '../../Components/Shared/Explorer/Explorer';

const SubExplorer = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className='min-vh-100'>
            <div className='container mt-4'>
                <h1 className="titleOfCentral mt-3 mb-4">Explorer</h1>

                <Explorer />
            </div>
        </section>
    );
};

export default SubExplorer;