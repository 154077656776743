import React, { useEffect } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import BlogSingleCard from './BlogSingleCard';
import './blogV2.css';

const dataFake = [
    { id: 1, date: "September 25 2023", time: "3 Min Read", url: "What-Are-Digital-Credentials-and-How-it-can-help-Educational-institutions?", CardTitle: "What Are Digital Credentials and How it can help Educational institutions?", title: "What Are Digital Credentials and How it can help Educational institutions?", description: "Digital credentials are electronic records in form of Digital certificates & documents that represent an individual's achievements, skills, or qualifications. They serve as a verifiable and secure way to showcase a person's educational accomplishments. These credentials can include academic degrees, certifications, badges, and other qualifications earned by a student.", img: "/assets/images/blog-thumb.png", mT: "Revolutionizing Digital Credential with Blockchain Technology", mD: "Zecurechain ensures security and transparency by transforming the landscape of digital credential management in India and beyond." },

    { id: 2, date: "October 07 2023", time: "3 Min Read", url: "Shareable-Blockchain-Digital-Credential-Platform", CardTitle: "Zecurechain: Shareable Blockchain Digital Credential Platform", title: "Zecurechain: Shareable Blockchain Digital Credential Platform", description: "In today's digitally-driven landscape, ensuring the security and reliability of credentials is paramount. Conventional methods of certificate management often fall short, leaving room for vulnerabilities. This is where Zecurechain steps in, introducing a cutting-edge SaaS solution that revolutionizes how we handle digital credentials. Zecurechain recognizes the importance of safeguarding diverse digital credentials, from educational certificates to professional accreditations and critical documents. Leveraging the power of blockchain, our platform introduces an unprecedented level of security, transparency, and accessibility to credential management, culminating in a robust Blockchain Credential Verification process.", img: "/assets/images/blog-thumb2.png", mT: "Revolutionizing Digital Credential with Blockchain Technology", mD: "Zecurechain ensures security and transparency by transforming the landscape of digital credential management in India and beyond." },

    { id: 3, date: "October 16 2023", time: "3 Min Read", url: "how-digital-certificates-are-transforming-document-security-and-combating-forgeries", CardTitle: "Transforming document security and Digital credential", title: "Zecurechain: Transforming Document Security, The Power of Digital Certificates and Credentials", description: "In today's digital age, the battle against document forgeries and ensuring the integrity of sensitive information is of paramount importance. Digital certificates and credentials have emerged as formidable tools in this fight, revolutionizing document security. In this article, we'll delve into how digital certificates are transforming document security and combating forgeries.", img: "/assets/images/blog-thumb3.png", mT: "Zecurechain: Enhance Document Security with Digital Certificates", mD: "Zecurechain's digital certificates safeguard your documents, prevent forgeries, and ensure authenticity. Enhance document security with Zecurechain." },


]

const BlogsV2 = () => {
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <section style={{ minHeight: '90vh' }}>
            <Helmet>
                <title>Zecurechain | What Are Digital Credentials</title>
                <meta name="description" content="Discover the power of Zecurechain's digital credentials and their transformative role in enhancing educational institutions." />
            </Helmet>
            <div className='container mb-5'>
                <div className='text-start mt-4 mb-4'>
                    <div className='d-flex justify-content-start align-items-center' style={{ gap: '5px' }}>
                        <ArrowBackIcon style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
                        <h4 className='dTitle' >Blogs</h4>
                    </div>
                    <p style={{
                        color: "#545454",
                        textAlign: "start",
                        fontFamily: 'Saira, sans-serif',
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal"
                    }} className='mb-0'>Enlightening Insights & Clear-Cut Content</p>
                </div>
                <div className='row gx-5 gy-4 mx-auto'>
                    {
                        dataFake.map(data => <BlogSingleCard data={data} dataFake={dataFake} />)
                    }
                </div>
            </div>
        </section>
    );
};

export default BlogsV2;