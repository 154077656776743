import { PaymentRequestButtonElement, PaymentElement, ExpressCheckoutElement, AddressElement } from "@stripe/react-stripe-js";
import { useRef, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import GooglePayComponent from "./GooglePayComponent";
import toast from "react-hot-toast";
import { PayPalButtons, PayPalScriptProvider, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import axios from "axios";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

export default function CheckoutForm({ checkoutPrice, email, packageName, onClose, usdPrice }) {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState();
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [emails, setEmail] = useState(email);
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isPayPal, setIsPayPal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [orderID, setOrderID] = useState(false);
    const paypal = useRef();
    const buttonInstance = useRef(null);
    const token = CryptoJS.AES.decrypt(localStorage.getItem("userToken"), process.env.REACT_APP_MY_SECRET_KYE).toString(CryptoJS.enc.Utf8);

    const updateUserAPI = async (email, packageName, clientSecret) => {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payment/update-user`, { email, packageName, clientSecret }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${JSON.parse(token)}`,
            }
        })
            .then(res => {
                if (res.status == 200) {
                    toast.success("Updated Subscription");
                }
            })
            .catch((e) => {
                console.log(e);
                toast.error("Update Failed to Subscription");
            })
            .finally(() => {
                onClose();
            })
    }

    useEffect(() => {
        const createPayPalButton = () => {
            if (buttonInstance.current) {
                // If a button instance exists, unmount it first
                buttonInstance.current.close();
            }

            buttonInstance.current = window.paypal
                .Buttons({
                    fundingSource: 'paypal',
                    style: {
                        layout: 'horizontal',
                        color: 'gold',
                        shape: 'rect',
                        label: 'paypal'
                    },

                    createOrder: (data, actions, err) => {

                        return actions.order.create({
                            purchase_units: [
                                {
                                    intent: "CAPTURE",
                                    description: `Pay Us $${usdPrice}`,
                                    amount: {
                                        currency_code: "USD",
                                        value: usdPrice,
                                    },
                                },
                            ],
                            application_context: {
                                shipping_preference: "NO_SHIPPING",
                            },
                        })
                            .then((orderID) => {
                                setOrderID(() => orderID); // console.log(orderID);

                                return axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payment/create-payment-secret`, { email, clientSecret: orderID }, {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${JSON.parse(token)}`,
                                    }
                                })
                                    .then(res => {
                                        if (res.status === 200) {
                                            return orderID;
                                        }
                                        throw new Error("Payment secret request failed.");
                                    })
                                    .catch((e) => {
                                        console.error("Error creating payment secret:", e);
                                        toast.error("Please try again!");
                                        throw e; // Propagate the error further if needed
                                    });
                            })
                            .catch(e => {
                                console.error("Error creating order:", e);
                                toast.error("Payment Failed!");
                                throw e; // Propagate the error further if needed
                            });

                    },
                    onApprove: async (data, actions) => {
                        const order = await actions.order.capture();
                        console.log(order);

                        setSuccess(true);
                        toast.success("Payment successful");
                        await updateUserAPI(email, packageName, orderID);

                    },
                    onError: (err) => {
                        console.log(err);
                        toast.error("Payment capture failed.");
                    },
                })
            buttonInstance.current.render(paypal.current);

        };
        createPayPalButton();
    }, [email, token, usdPrice]);

    useEffect(() => {
        if (stripe) {
            const pr = stripe.paymentRequest({
                country: 'IN',
                currency: 'inr',
                total: {
                    label: packageName,
                    amount: checkoutPrice,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });

            // Check the availability of the Payment Request API.
            pr.canMakePayment().then(result => {
                if (result) {
                    setPaymentRequest(() => pr);
                }
                else {
                    setPaymentRequest(() => null);
                    setMessage("Google Pay support not found. Check the pre-requisites above and ensure you are testing in a supported browser.");
                    // alert('Google Pay support not found. Check the pre-requisites above and ensure you are testing in a supported browser.');
                }
            });

            pr.on('paymentmethod', async (ev) => {

                // payment intent and store its client_secret.
                const { error: backendError, clientSecret } = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payment/create-payment-intent`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${JSON.parse(token)}`,
                    },
                    body: JSON.stringify({
                        currency: 'inr',
                        amount: checkoutPrice,
                        email: email,
                        paymentMethodType: 'card',
                    }),
                }).then((r) => r.json());

                if (backendError) {
                    toast.error(backendError.message);
                    // alert(backendError.message);
                    ev.complete('fail');

                }

                //   alert('Client secret returned.');

                // Confirm the PaymentIntent without handling potential next actions (yet).
                const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
                    clientSecret,
                    { payment_method: ev.paymentMethod.id },
                    { handleActions: false }
                );

                if (confirmError) {
                    // Report to the browser that the payment failed, prompting it to
                    // re-show the payment interface, or show an error message and close
                    // the payment interface.
                    ev.complete('fail');
                } else {
                    // Report to the browser that the confirmation was successful, prompting
                    // it to close the browser payment method collection interface.
                    ev.complete('success');
                    // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
                    // handle the flow. If using an API version older than "2019-02-11"
                    // instead check for: `paymentIntent.status === "requires_source_action"`.
                    if (paymentIntent.status === "requires_action") {
                        // Let Stripe.js handle the rest of the payment flow.
                        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret);
                        if (error) {
                            toast.error(error.message);
                            // alert(error.message);
                        } else {
                            toast.success(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
                            await updateUserAPI(email, packageName, clientSecret);
                            // alert(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
                        }

                    } else {
                        toast.success(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
                        // alert(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
                    }
                }
            });

        }
    }, [stripe, checkoutPrice, packageName, token, email]);


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            // console.log("return");
            return;
        }

        setIsProcessing(true);
        const { error: submitError } = await elements.submit();
        if (submitError) {
            setMessage(submitError.message);
            setIsProcessing(false);
            return;
        }
        setIsProcessing(true);
        const addressElement = elements.getElement('address');

        const { complete, value } = await addressElement.getValue();

        if (!complete) {
            setMessage("Please complete billing part");
            toast.error("Please complete billing part");
            setIsProcessing(false);
            return;
        }
        // payment intent and store its client_secret.
        const { error: backendError, clientSecret } = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payment/create-payment-intent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${JSON.parse(token)}`,
            },
            body: JSON.stringify({
                currency: 'inr',
                amount: checkoutPrice,
                email: email,
                name: value?.name,
                address: value?.address,
                paymentMethodType: 'card',
            }),
        }).then((r) => r.json());

        if (backendError) {
            alert(backendError.message);
            setIsProcessing(false);
            return;
        }
        // console.log(clientSecret);
        const { error } = await stripe.confirmPayment({
            elements,
            clientSecret,
            confirmParams: {
                // Make sure to change this to your payment completion page

                return_url: `${window.location.origin}/dashboard/dashboard-home/${clientSecret}`,

            },
        });
        if (!error) {
            await updateUserAPI(email, packageName, clientSecret);
        }
        console.log(error);
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
            navigate("/pricing");
            toast.error(error.message);
        } else {
            setMessage("An unexpected error occurred.");
            navigate("/pricing");
            toast.error(error.message);
        }

        setIsProcessing(false);
    };


    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    description: `Pay Us $${usdPrice}`,
                    amount: {
                        currency_code: "USD",
                        value: usdPrice,
                    },
                },
            ],
            application_context: {
                shipping_preference: "NO_SHIPPING",
            },
        })
            .then((orderID) => {
                setOrderID(() => orderID); console.log(orderID);

                return axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/payment/create-payment-secret`, { email, clientSecret: orderID }, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${JSON.parse(token)}`,
                    }
                })
                    .then(res => {
                        if (res.status === 200) {
                            return orderID;
                        }
                        throw new Error("Payment secret request failed.");
                    })
                    .catch((e) => {
                        console.error("Error creating payment secret:", e);
                        toast.error("Please try again!");
                        throw e; // Propagate the error further if needed
                    });
            })
            .catch(e => {
                console.error("Error creating order:", e);
                toast.error("Payment Failed!");
                throw e; // Propagate the error further if needed
            });
    };

    const onApprove = async (data, actions) => {
        return actions.order.capture()
            .then(function (details) {
                const { payer } = details;
                setSuccess(true);
                toast.success("Payment successful");
            })
            .catch(e => {
                console.error("Error capturing payment:", e);
                toast.error("Payment capture failed.");
                throw e; // Propagate the error further if needed
            });
    };


    const ButtonWrapper = ({ showSpinner }) => {
        const [{ isPending }] = usePayPalScriptReducer();
        const style = {
            // layout: 'vertical', // Set the layout to 'horizontal' to show only the PayPal button
            layout: 'horizontal'
        };

        return (
            <>
                {(showSpinner && isPending) && <div className="spinner" />}
                <PayPalButtons
                    style={style}
                    disabled={false}
                    forceReRender={[style]}
                    fundingSource={'paypal'}
                    createOrder={createOrder}
                    onApprove={onApprove}
                />
            </>
        );
    }
    const paymentElementOptions = {
        layout: {
            type: 'accordion',
            defaultCollapsed: false,
            radios: false,
            spacedAccordionItems: true
        },
    }
    return (

        <div>
            {/* <div id="checkout-page">
                <ExpressCheckoutElement onConfirm={onConfirm} />
                {errorMessage && <div>{errorMessage}</div>}
            </div> */}
            {/* <button onClick={handleGooglePay}>Pay with Google Pay</button> */}
            {/* <GooglePayComponent /> */}

            {/* <PayPalScriptProvider
                options={{ clientId: `${process.env.REACT_APP_PAYPAL_CLIENT_KEY}`, components: "buttons", currency: "USD", intent: "capture", }}
            >
                <ButtonWrapper showSpinner={false} />
            </PayPalScriptProvider> */}
            <div ref={paypal}></div>

            {paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}
            {/* 
            <div id="payment-request-button">
            </div> */}
            <div className="mt-2">
                <form id="payment-form" onSubmit={handleSubmit}>

                    <AddressElement options={{
                        mode: 'billing',
                        // defaultValues: {
                        //     name: 'Jane Doe',
                        //     address: {
                        //         line1: '354 Oyster Point Blvd',
                        //         line2: '',
                        //         city: 'South San Francisco',
                        //         state: 'CA',
                        //         postal_code: '94080',
                        //         country: 'US',
                        //     }
                        // }
                    }} />

                    <PaymentElement id="payment-element" />
                    <Button variant="warning" disabled={isProcessing || !stripe || !elements} id="submit" type="submit" className="my-3">
                        <span id="button-text">
                            {isProcessing ? "Processing ... " : "Pay now"}
                        </span>
                    </Button>
                    {message && <div id="payment-message" className="text-danger">{message}</div>}
                </form>
            </div>
        </div>
    );
}
