import React from 'react';
import './Gifloader.css';

const GifLoaderForExplorer = () => {
    return (
        <section className="container">
            <div className="text-center">
                <img alt="" src="/assets/images/loader.gif" />
                <div>Do Not Exit Window, we Verifying your details  </div>
            </div>
        </section>
    );
};

export default GifLoaderForExplorer;