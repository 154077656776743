import React, { forwardRef, useContext, useEffect, useRef, useState } from "react";
import "./DashboardCertificates.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import html2canvas from "html2canvas";
import axios from "axios";
import CryptoJS from "crypto-js";
import { toast } from "react-hot-toast";
import QRCode from "qrcode";
import { UserAuthContext } from "../../../../Context/UserContext/UserContext";
import { BlockchainAuthContext } from "../../../../Context/UserContext/BlockchainContext";
import ShowUser from "./ShowUser";
import { getSelectedInlineStyle } from "draftjs-utils";
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromRaw,
  Modifier,
  SelectionState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  toggleCustomInlineStyle,
  getSelectionCustomInlineStyle,
} from "draftjs-utils";
import * as XLSX from "xlsx";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Alert, Stack, Tooltip } from "@mui/material";
import UploadSuccess from "../../../../Components/Shared/UploadedSuccessfully/UploadSuccess";
import Gifloader from "../../../../Components/Shared/GifLoader/Gifloader";
import DashboardShowUserCard from "./DashboardShowUserCard";

const DashboardCreateCertificate = () => {
  const navigate = useNavigate();
  const { isInFolder, folder, isSub, folderId } = useParams();
  const { user, allUser, userRefetch, setUserRefetch, cerTemp, folderStructure, setFolderStructure } =
    useContext(UserAuthContext);
  // console.log(folderStructure);
  const { mintNFT } = useContext(BlockchainAuthContext);
  const [name, setName] = useState("");
  const [details, setDetails] = useState("");
  const [sig1, setSig1] = useState({ origin: null, show: null });
  const [sig2, setSig2] = useState({ origin: null, show: null });
  const [logo, setLogo] = useState({ origin: null, show: null });
  const [dateCom, setDateCom] = useState("");
  const [dateValid, setDateValid] = useState("");
  const [dateIssue, setDateIssue] = useState("");
  const [img, setImg] = useState("");
  const certificateTemplate = useRef(null);
  const [date, setDate] = useState("");
  const [date2, setDate2] = useState("");
  const [date3, setDate3] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const qrCodeBody = useRef(null);
  const [email, setEmail] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState("");
  const [upType, setUpType] = useState("");
  const [serial, setSerial] = useState("");
  const [tempImgs, setTempImgs] = useState([]);
  const [bulkChecking, setBulkChecking] = useState(false);
  const [isWarn, setIsWarn] = useState(true);
  const [detailsCardShow, setDetailsCardShow] = useState(false);

  const [elements, setElements] = useState({
    name: null,
    tHash: null,
    ipHash: null,
    qr: null,
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isFocusName, setIsFocusName] = useState(false);
  const [isFocusDetails, setIsFocusDetails] = useState(false);
  const [contentWidth, setContentWidth] = useState(0);
  const [contentWidthD, setContentWidthD] = useState(0);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const inputDate1 = useRef(null);
  const inputDate2 = useRef(null);
  const inputDate3 = useRef(null);

  const handleLabelClick1 = () => {
    fileInputRef1?.current.click(); // Trigger the hidden file input when the label is clicked
  };

  const handleLabelClick2 = () => {
    fileInputRef2?.current.click(); // Trigger the hidden file input when the label is clicked
  };

  const handleLabelClick3 = () => {
    fileInputRef3?.current.click(); // Trigger the hidden file input when the label is clicked
  };
  const userByEmail = allUser && allUser?.reduce((acc, user) => {
    acc[user.email] = user;
    return acc;
  }, {});
  // Access specific query parameters
  let isBulk = queryParams.get("isBulk");
  let catchId = localStorage.getItem("imgID");
  const imgData = cerTemp.find((data) => data?.id == catchId);

  useEffect(() => {
    if (isBulk == "true") {
      setBulkChecking(() => true);
    } else {
      setBulkChecking(() => false);
    }
  }, [isBulk]);
  // console.log(bulkChecking, 'bulkCheck');
  // const data = draftToHtml(convertToRaw(valueAbout.getCurrentContent()));
  // Default font and color styles
  const defaultFont = "Playball";
  const defaultColor = "rgb(0, 0, 139)";

  const [nameRich, setNameRich] = useState(() => EditorState.createEmpty());
  const [detailsRich, setDetailsRich] = useState(() =>
    EditorState.createEmpty()
  );
  // useEffect(() => {
  //     const fontSize = getSelectionCustomInlineStyle(nameRich, ['FONTSIZE',]).FONTSIZE

  //     setNameRich(() => toggleCustomInlineStyle(nameRich, 'fontFamily', 'Playball', nameRich, 'colorPicker', 'rgb(0, 0, 139)'));

  // }, [nameRich]);
  const [excelFile, setExcelFile] = useState(null);
  const [typeError, setTypeError] = useState(null);

  // submit state
  const [excelData, setExcelData] = useState(null);
  // onchange event
  const handleFile = (e) => {
    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        setTypeError(null);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(e.target.result);
        };
      } else {
        setTypeError("Select Excel File Only");
        setExcelFile(null);
      }
    } else {
      console.log("Please select your file");
    }
  };

  const handleClickOpen = () => {
    setOpen(() => true);
  };

  const handleClose = () => {
    setUserRefetch(!userRefetch);
    setOpen(() => false);
  };
  const [web3Info, setWeb3Info] = useState({
    IPFS: null,
    tHash: null,
    TId: null,
  });
  const [loading, setLoading] = useState(false);
  const handleFileUpload = (e) => {
    setSelectedFiles([...e.target.files]);
  };

  const generateQR = async (IPFS, tHash) => {
    try {
      // console.log("after gen", name, email, user?.email, user?.org_individual_Name, details, date, IPFS, tHash);
      // let text = `Certificate Name: ${name}, Receiver Email: ${email}, Issuer Name: ${user?.org_individual_Name}, Issuer Email: ${user?.email}, Course: ${details}, Date: ${date}, Certificate Hash: ${IPFS}, Transaction Hash: ${tHash}.`;
      let text = `https://zecurechain.com/qr-details/${IPFS}`;
      await QRCode.toDataURL(text).then(setSrc);
      const genQR = await QRCode.toDataURL(text);
      // console.log(genQR);
      return genQR;
    } catch (err) {
      console.error(err, "err");
    }
  };


  useEffect(() => {
    // Check if qrCodeBody.current is set and if it is an HTMLCanvasElement
    window.scrollTo(0, 0);
    if (catchId > 7 && catchId < 11) {
      navigate(-1);
      toast.dismiss();
      toast.error("Coming soon!");
    }
    if (user?.types != "issuer") {
      navigate("/");
    }
    generateQR();
  }, [catchId, user]);


  const exportAsImage = async () => {
    const catchDiv = document.getElementById("cerToSVG");
    if (certificateTemplate.current) {
      const canvas = await html2canvas(certificateTemplate.current, {
        allowTaint: true,
        useCORS: true,
      });
      const image = canvas.toDataURL("image/jpeg", 1.0);
      // download the image
      // console.log(src, "img");
      setImg(() => image);
      setImg(() => image);
      setImg(() => image);
      const blobing = await (await fetch(image)).blob();
      setUpType(() => blobing);
      return blobing;

      // console.log(blobing);
    }
  };
  useEffect(() => {
    const element = certificateTemplate.current;
    // console.log(element);
  }, []);
  const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);

    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const day = dateObject.getDate();
    const month = months[dateObject.getMonth()];
    const year = dateObject.getFullYear();

    const day2 = dateObject.getDate().toString().padStart(2, "0");
    const month2 = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const year2 = dateObject.getFullYear();

    const formattedDate = `${imgData?.id == 3 || imgData?.id == 4 || imgData?.id == 5 ||
      imgData?.id == 7 || imgData?.id == 8 || imgData?.id == 12 ||
      imgData?.id == 13 || imgData?.id == 15 || imgData?.id == 16 ? `${day2}-${month2}-${year2}` : imgData?.id == 14 ? `${day} ${month} ${year}` :
      `on ${month} ${day}, ${year}`
      }`;

    return formattedDate;
  };


  const fixingDateCom = (e) => {
    const formattedDate = formatDate(e);
    setDate(() => e);
    setDateCom(() => formattedDate);
  };
  const fixingDateValid = (e) => {
    const formattedDate = formatDate(e);
    setDate2(() => e);
    setDateValid(() => formattedDate);
  };
  const fixingDateIssue = (e) => {
    const formattedDate = formatDate(e);
    setDate3(() => e);
    setDateIssue(() => formattedDate);
  };
  const maxCharacterLimit = imgData?.id == 13 ? 40 : imgData?.id == 14 ? 24 : 23;
  const getAppliedStyles = (editorState) => {
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const endOffset = selection.getEndOffset();
    const currentBlock = currentContent.getBlockForKey(startKey);
    const text = currentBlock.getText();

    const inlineStyles = [];

    currentBlock.findStyleRanges(
      (character) =>
        startOffset <= character.offset && character.offset <= endOffset,
      (start, end) => {
        const style = currentBlock.getInlineStyleAt(start);

        if (style.size > 0) {
          style.forEach((styleType) => {
            inlineStyles.push(styleType);
          });
        }
      }
    );
    // console.log(inlineStyles);
    return Array.from(new Set(inlineStyles)); // Remove duplicates
  };
  const test = () => {
    const appliedStyles = getAppliedStyles(nameRich);
    // console.log(appliedStyles);
    // console.log(draftToHtml(convertToRaw(nameRich.getCurrentContent())));
  };
  const handleInputChangeName = (editorState) => {
    // const currentInlineStyle = nameRich.getCurrentInlineStyle();
    // const currentInlineStyle = getSelectedInlineStyle(nameRich);
    // console.log(currentInlineStyle);

    setNameRich(() => editorState);
    // console.log(editorState)
  };
  let MAX_LINES = imgData?.id == 7 ? 3 : (imgData?.id == 3 ||
    imgData?.id == 4 ||
    imgData?.id == 5 ||
    imgData?.id == 6 ||
    imgData?.id == 8
    ? 2
    : 1);
  const maxCDetails = imgData?.id == 13 ? 56 : 40;

  useEffect(() => {
    const currentContent = nameRich.getCurrentContent();
    const selection = nameRich.getSelection();
    const startKey = selection.getStartKey();
    const currentBlock = currentContent.getBlockForKey(startKey);
    const plainText = currentContent.getPlainText("");
    // Create a temporary element to measure the width
    const tempElement = document.createElement("span");
    tempElement.style.whiteSpace = "nowrap";
    tempElement.style.visibility = "hidden";
    tempElement.innerText = plainText;

    // Append the temporary element to the document body
    document.body.appendChild(tempElement);

    // Get the width of the temporary element
    const contentWidth = tempElement.offsetWidth;

    // Remove the temporary element from the document body
    document.body.removeChild(tempElement);

    // console.log(contentWidth);
    setContentWidth(() => contentWidth);
    setName(() => plainText);

    const currentContentd = detailsRich.getCurrentContent();
    const selectiond = detailsRich.getSelection();
    const startKeyd = selectiond.getStartKey();
    const currentBlockd = currentContentd.getBlockForKey(startKeyd);
    const plainTextd = currentContentd.getPlainText("");
    // Create a temporary element to measure the width
    const tempElementD = document.createElement("span");
    tempElementD.style.whiteSpace = "nowrap";
    tempElementD.style.visibility = "hidden";
    tempElementD.innerText = plainTextd;

    // Append the temporary element to the document body
    document.body.appendChild(tempElementD);

    // Get the width of the temporary element
    const contentWidthD = tempElementD.offsetWidth;

    // Remove the temporary element from the document body
    document.body.removeChild(tempElementD);

    // console.log(contentWidthD);
    setContentWidthD(() => contentWidthD);
    setDetails(() => plainTextd);

    // console.log("info", plainText, plainTextd);
  }, [nameRich, detailsRich]);

  const handleBeforeInputName = (chars, editorState) => {
    if (isBulk == "true") return "handled";

    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const currentBlock = currentContent.getBlockForKey(startKey);
    const plainText = currentContent.getPlainText("");
    const characterCount = plainText.length;

    if (characterCount >= maxCharacterLimit) {
      toast.dismiss();
      toast.error("Character limit reached!");
      return "handled"; // Prevent further input
    }

    if (
      currentBlock.getType() === "unstyled" &&
      currentBlock.getLength() >= maxCharacterLimit
    ) {
      return "handled"; // Prevent further input
    }
    return "not-handled"; // Allow input
  };
  const handleBeforeInput = (chars, editorState) => {
    if (isBulk == "true") return "handled";
    const currentContent = editorState.getCurrentContent();
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const currentBlock = currentContent.getBlockForKey(startKey);
    const plainText = currentContent.getPlainText("");
    const characterCount = plainText.length;

    if (characterCount >= MAX_LINES * maxCDetails) {
      toast.dismiss();
      toast.error("Character limit reached!");
      return "handled"; // Prevent further input
    }
    if (
      currentBlock.getType() === "unstyled" &&
      currentBlock.getLength() >= maxCDetails
    ) {
      // Insert a newline character
      const newContentState = Modifier.splitBlock(currentContent, selection);
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        "split-block"
      );
      setDetailsRich(() => newEditorState);
      return "handled"; // Prevent further input
    }

    return "not-handled"; // Allow input
  };

  const handleReturn = (event, editorState) => {
    return "handled"; // Prevent the default behavior of creating a new line
  };

  const handlePastedText = (text, html, editorState) => {
    // Prevent any pasted text
    if (isBulk == "true") {
      return "handled";
    }
  };

  const handleKeyDown = (e, editorState) => {
    // Prevent any text removal using backspace or delete key
    if (isBulk == "true") {
      // console.log("handleKeyDown called", e);
      const selection = editorState.getSelection();
      if (selection.isCollapsed() && (e === "backspace" || e === "delete")) {
        // e.preventDefault();
        // console.log("handleKeyDown called two");
        return "handled";
      }
    }
  };

  const handleBeforeTextPaste = (text) => {
    // Prevent any pasted text
    if (isBulk == "true") {
      return text;
    }
  };

  const handlePaste = (text, html, editorState) => {
    // Prevent any pasted text
    if (isBulk == "true") {
      return "handled";
    }
  };

  const handleInputChangeDetails = (e) => {
    setDetailsRich(() => e);
  };

  const validEmail = async (e) => {
    const finding = userByEmail[e.target.value];
    if (finding) {
      setEmail(() => e.target.value);
      setWalletAddress(() => finding?.walletAddress);
    } else {
      const token = CryptoJS.AES.decrypt(
        localStorage.getItem("userToken"),
        process.env.REACT_APP_MY_SECRET_KYE
      ).toString(CryptoJS.enc.Utf8);

      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/e-walletAddress`,
          { email: e.target.value },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `Bearer ${JSON.parse(token)}`,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            // console.log(res.data);
            setEmail(() => e.target.value);
            setWalletAddress(() => res.data?.data?.walletAddress);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error("Something is wrong!");
          setEmail(() => "");
        });
    }
  };
  const validEmailExcel = async (e) => {
    const finding = userByEmail[e];
    if (finding) {
      setEmail(() => e);
      setWalletAddress(() => finding?.walletAddress);
    } else {
      const token = CryptoJS.AES.decrypt(
        localStorage.getItem("userToken"),
        process.env.REACT_APP_MY_SECRET_KYE
      ).toString(CryptoJS.enc.Utf8);

      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/e-walletAddress`,
          { email: e },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `Bearer ${JSON.parse(token)}`,
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            // console.log(res.data);
            setEmail(() => e);
            setWalletAddress(() => res.data?.data?.walletAddress);
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error("Something is wrong!");
          setEmail(() => "");
        });
    }
  };
  const validEmailExcelArray = async (e) => {
    const finding = userByEmail[e];
    if (finding) {
      // setEmail(() => e);
      return finding?.walletAddress;
    } else {
      const token = CryptoJS.AES.decrypt(
        localStorage.getItem("userToken"),
        process.env.REACT_APP_MY_SECRET_KYE
      ).toString(CryptoJS.enc.Utf8);

      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/e-walletAddress`,
        { email: e },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      );
      if (res.status == 200) {
        // console.log(res.data);
        // setEmail(() => e);
        return res.data?.data?.walletAddress;
      }
    }
  };
  const convertExcelDateV2 = (excelDate) => {
    const date = new Date((excelDate - 25569) * 86400 * 1000); // Convert Excel date to milliseconds
    return date;
  };

  const convertExcelDate = (excelDate) => {
    const date = new Date((excelDate - 25569) * 86400 * 1000); // Convert Excel date to milliseconds

    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based, so add 1
    const year = date.getFullYear();

    let formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
  };
  // submit event
  const handleFileSubmit = (e) => {
    e.preventDefault();
    setBulkChecking(() => false);
    window.scrollTo(0, 0);
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      // const data =JSON.stringify(withOutString);

      setExcelData(() => data);
      validEmailExcel(data[0].Email);
      // setEmail(() => data[0].Email);
      setName(() => data[0].Name);
      setDetails(() => data[0]?.Course);
      // setNameRich(() => data[0].Name);
      // setDetailsRich(() => data[0]?.Course);
      let convertOne;
      let convertTwo;
      let convertOneV2;
      let convertTwoV2;
      let convertThree;
      let convertThreeV2;
      if (imgData?.id == 12 || imgData?.id == 13 || imgData?.id == 15 || imgData?.id == 16) {
        convertOne = convertExcelDate(data[0]?.From_dd_mm_yyyy);
        convertTwo = convertExcelDate(data[0]?.TO_dd_mm_yyyy);
        convertOneV2 = convertExcelDateV2(data[0]?.From_dd_mm_yyyy);
        convertTwoV2 = convertExcelDateV2(data[0]?.TO_dd_mm_yyyy);
        convertThree = convertExcelDate(data[0]?.Issue_Date_dd_mm_yyyy);
        convertThreeV2 = convertExcelDateV2(data[0]?.Issue_Date_dd_mm_yyyy);
      }
      else {
        convertOne = convertExcelDate(data[0]?.Date_of_Completion);
        convertTwo = convertExcelDate(data[0]?.Validity);
        convertOneV2 = convertExcelDateV2(data[0]?.Date_of_Completion);
        convertTwoV2 = convertExcelDateV2(data[0]?.Validity);
      }


      const formattedDateOne = formatDate(convertOne);
      const formattedDateTwo = formatDate(convertTwo);
      const formattedDateThree = formatDate(convertThree);

      setDate(() => convertOneV2);
      setDateCom(() => formattedDateOne);
      setDate2(() => convertTwoV2);
      setDateValid(() => formattedDateTwo);
      setSerial(() => data[0]?.Certificate_No);
      setDate3(() => convertThreeV2);
      setDateIssue(() => formattedDateThree);

      const blocksFromHtml = htmlToDraft(data[0].Name);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      // console.log(EditorState.createWithContent(contentState));
      setNameRich(() => EditorState.createWithContent(contentState));

      const blocksFromHtml2 = htmlToDraft(data[0].Course);
      const contentState2 = ContentState.createFromBlockArray(
        blocksFromHtml2?.contentBlocks,
        blocksFromHtml2?.entityMap
      );
      // console.log(EditorState.createWithContent(contentState2));

      setDetailsRich(() => EditorState.createWithContent(contentState2));
    }
  };
  const generateQRExcel = async (
    nameex,
    detailsex,
    dateex,
    userex,
    IPFS,
    tHash,
    emailex,
    userEmailex
  ) => {
    try {
      // console.log("after gen", IPFS, tHash);

      // let text = `Certificate Name: ${nameex}, Receiver Email: ${emailex}, Issuer Name: ${userex}, Issuer Email: ${userEmailex}, Course Details: ${detailsex}, Date: ${dateex}, Certificate Hash: ${IPFS}, Transaction Hash: ${tHash} .`;
      let text = `https://zecurechain.com/qr-details/${IPFS}`;
      await QRCode.toDataURL(text).then(setSrc);
    } catch (err) {
      console.error(err, "err");
    }
  };
  const generateQRExcelSecondEdition = async (
    nameex,
    detailsex,
    dateex,
    userex,
    IPFS,
    tHash,
    emailex,
    userEmailex
  ) => {
    try {
      // console.log("after gen", IPFS, tHash);

      // let text = `Certificate Name: ${nameex}, Receiver Email: ${emailex}, Issuer Name: ${userex}, Issuer Email: ${userEmailex}, Course Details: ${detailsex}, Date: ${dateex}, Certificate Hash: ${IPFS}, Transaction Hash: ${tHash} .`;
      let text = `https://zecurechain.com/qr-details/${IPFS}`;
      await QRCode.toDataURL(text).then(setSrc);
      const src = await QRCode.toDataURL(text); // Wait for QR code generation
      return src; // Return the generated QR code
    } catch (err) {
      console.error(err, "err");
    }
  };

  const maxCer = {
    basic: (user?.types == "individual") ? 0 : 2,
    foundationCY: (user?.types == "individual") ? 0 : 1000,
    foundationCM: (user?.types == "individual") ? 0 : 50,
    intermediateCY: (user?.types == "individual") ? 0 : 1500,
    intermediateCM: (user?.types == "individual") ? 0 : 100,
    enterpriseCY: (user?.types == "individual") ? 0 : user?.type_of_subscription?.isEnterprise[0]?.cer,
    enterpriseCM: (user?.types == "individual") ? 0 : user?.type_of_subscription?.isEnterprise[0]?.cer,
  };
  const maxDoc = {
    basic: 2,
    foundationDY: 600,
    foundationDM: 50,
    intermediateDY: 1200,
    intermediateDM: 100,
    enterpriseDY: user?.type_of_subscription?.isEnterprise[0]?.docs,
    enterpriseDM: user?.type_of_subscription?.isEnterprise[0]?.docs
  };

  const reChangeCerImages = async (ipHashEX, hashEX, nameEX, courseNameEX, dateOfCompletionImgEX, excelDataP, certificateEX, issuerEX, issuerNameEX, emailEX, logo1, logo2, logo3, validityImgEX, serialNoEX, issuesEX) => {
    setUserRefetch(!userRefetch);
    setFolderStructure({ isInFolder: false, folder: null, isSub: false, folderId: null });
    let info = { folder: folder, number: excelData?.length, sub: "Certificate" };
    localStorage.setItem("Info", JSON.stringify(info));
    navigate("/dashboard/dashboard-upload-confirmation");
    let qrEX = [];
    for (let i = 0; i < excelDataP.length; i++) {
      // console.log("scan", excelDataP[i].Name,
      //   excelDataP[i].Course,
      //   convertExcelDate(excelDataP[i].Date_of_Completion),
      //   user?.org_individual_Name,
      //   ipHashEX[i],
      //   hashEX,
      //   excelDataP[i].Email,
      //   user?.email);
      const qrCatch = await generateQRExcelSecondEdition(
        excelDataP[i].Name,
        excelDataP[i].Course,
        convertExcelDate(excelDataP[i].Date_of_Completion),
        user?.org_individual_Name,
        ipHashEX[i],
        hashEX,
        excelDataP[i].Email,
        user?.email
      );

      qrEX.push(qrCatch);
      // console.log(qrCatch);
    }
    // console.log(qrEX);
    const dataExcel = {
      nameEX: nameEX,
      courseNameEX: courseNameEX,
      dateOfCompletionImgEX: dateOfCompletionImgEX,
      validityImgEX: validityImgEX,
      qrEX: qrEX,
      imageUrls: certificateEX,
      ipHashEX: ipHashEX,
      emailEX: emailEX,
      serialNoEX: serialNoEX,
      issuesEX: issuesEX
    };

    const dataForm = new FormData();
    dataForm.append("logo1", sig1?.origin);
    dataForm.append("logo2", logo?.origin);
    dataForm.append("logo3", sig2?.origin);
    dataForm.append("imgID", catchId);
    dataForm.append("n", excelDataP?.length);
    dataForm.append("hashEX", hashEX);
    dataForm.append("issuerNameEX", issuerNameEX);
    dataForm.append("issuerEX", issuerEX);
    dataForm.append("dataExcel", JSON.stringify(dataExcel));
    // console.log("Enter the second edition!");

    const token = CryptoJS.AES.decrypt(localStorage.getItem("userToken"), process.env.REACT_APP_MY_SECRET_KYE).toString(CryptoJS.enc.Utf8);

    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/certificate/updateToImage`,
        dataForm,
        {
          headers: {
            // "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${JSON.parse(token)}`,
          },
        }
      )
      .then(res => {
        // console.log(res.data);
        setUserRefetch(!userRefetch);
      })
      .catch((e) => {
        console.log(e);
        setUserRefetch(!userRefetch);
      })

  }

  const subCerMulti = async (e) => {
    e.preventDefault();
    if (user?.types === "individual") {
      return toast.error("Individual users are not allowed to issue!")
    }

    setIsWarn(true);
    setLoading(true);
    const token = CryptoJS.AES.decrypt(
      localStorage.getItem("userToken"),
      process.env.REACT_APP_MY_SECRET_KYE
    ).toString(CryptoJS.enc.Utf8);

    let userSub;
    let userSub1 = (user?.type_of_subscription?.name).find(data => data[data.length - 2] == 'C');
    let userSub2 = (user?.type_of_subscription?.name).find(data => data == "basic");
    if (!userSub1 && !userSub2) {
      return 'Your subscription is not available';
    }
    userSub = userSub1 ? userSub1 : userSub2;

    let purchasedDateOrigin = (user?.type_of_subscription?.purchasedDate).find(data => data?.packageName === userSub);
    const givenDate = new Date(purchasedDateOrigin?.date);
    const currentDate = new Date();

    const givenYear = givenDate.getFullYear();
    const givenMonth = givenDate.getMonth();
    const givenDay = givenDate.getDate();

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const oneYearAfterGivenDate = new Date(givenYear + 1, givenMonth, givenDay);
    const oneMonthAfterGivenDate = new Date(givenYear, givenMonth + 1, givenDay);
    let lastChar = userSub[userSub.length - 1];
    const tracks = user?.type_of_subscription?.track;
    const currentYearIndex = tracks.findIndex(entry => entry.year === currentYear);
    let subs = (lastChar === 'M') ? oneMonthAfterGivenDate : oneYearAfterGivenDate;
    // console.log(currentDate, oneYearAfterGivenDate, lastChar, subs)
    // isExpire?
    if (currentDate >= subs) {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-expired`, { email: user?.email, packageName: userSub }, {
        headers: {
          "Content-Type": "Application/json",
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      })
        .then(res => {
          // console.log(res.data);
        })
        .catch((e) => {
          console.log(e);
        })
      setIsWarn(false);
      setLoading(false);
      return toast.error("Your subscription has expired!");
    }


    if ((user?.type_of_subscription.cer + excelData?.length) < maxCer[userSub]) {
      // let dateOfCompletion = e.target.dateOfCompletion.value;
      // let validity = e.target.validity.value;

      let emailEX = [];
      let nameEX = [];
      let courseNameEX = [];
      let dateOfCompletionEX = [];
      let validityEX = [];
      let dateOfCompletionImgEX = [];
      let validityImgEX = [];
      let walletAddressEX = [];
      let isInFolderEX = isInFolder;
      let folderEX = folder;
      let isSubEX = isSub;
      let folderIdEX = folderId;
      let hashEX;
      let ipHashEX = [];
      let issuerEX = user?.email;
      let issuerNameEX = user?.org_individual_Name ? user?.org_individual_Name : "";
      let certificateEX = [];
      let n = excelData?.length;
      let logo1 = sig1?.origin;
      let logo2 = logo?.origin;
      let logo3 = sig2?.origin;
      let qrEX = [];
      let serialNoEX = [];
      let issuesEX = [];



      for (let i = 0; i < excelData?.length; i++) {

        if (excelData[i].Email.length <= 0 || excelData[i].Name <= 0 || excelData[i].Course <= 0 || excelData[i]?.Date_of_Completion <= 0 || excelData[i]?.Validity <= 0) {
          setLoading(false);
          setIsWarn(false);
          return toast.error("Please fill up all fields");
        }

        if (excelData[i].Email === user?.email) {
          setLoading(false);
          return toast.error("Use different mail Id");
        }
        emailEX.push(excelData[i]?.Email);
        nameEX.push(excelData[i]?.Name);
        courseNameEX.push(excelData[i]?.Course);
        serialNoEX.push(excelData[i]?.Certificate_No);
        let dateF;
        let dateE;
        let dateIss;
        let dateFX;
        let dateEX;
        if (imgData?.id == 12 || imgData?.id == 13 || imgData?.id == 15 || imgData?.id == 16) {
          dateF = formatDate(convertExcelDate(excelData[i]?.From_dd_mm_yyyy));
          dateE = formatDate(convertExcelDate(excelData[i]?.TO_dd_mm_yyyy));
          dateIss = formatDate(convertExcelDate(excelData[i]?.Issue_Date_dd_mm_yyyy));
          dateFX = convertExcelDate(excelData[i]?.From_dd_mm_yyyy);
          dateEX = convertExcelDate(excelData[i]?.TO_dd_mm_yyyy);
        }
        else {
          dateF = formatDate(convertExcelDate(excelData[i]?.Date_of_Completion));
          dateE = formatDate(convertExcelDate(excelData[i]?.Validity));
          dateFX = convertExcelDate(excelData[i]?.Date_of_Completion);
          dateEX = convertExcelDate(excelData[i]?.Validity);
        }
        issuesEX.push(dateIss);
        dateOfCompletionImgEX.push(dateF);
        validityImgEX.push(dateE);
        dateOfCompletionEX.push(dateFX);
        validityEX.push(dateEX);
        let wallet = await validEmailExcelArray(excelData[i].Email);
        if (wallet) {
          walletAddressEX.push(wallet);
        }
        await generateQRExcel(
          excelData[i].Name,
          excelData[i].Course,
          dateFX,
          user?.org_individual_Name,
          undefined,
          undefined,
          excelData[i].Email,
          user?.email
        );

        qrEX.push(src);
        // console.log(src);
      }
      // console.log(qrEX);
      const dataExcel = {
        nameEX: nameEX,
        courseNameEX: courseNameEX,
        dateOfCompletionImgEX: dateOfCompletionImgEX,
        validityImgEX: validityImgEX,
        qrEX: qrEX,
        serialNoEX: serialNoEX,
        issuesEX: issuesEX,
      };

      const dataForm = new FormData();
      dataForm.append("logo1", logo1);
      dataForm.append("logo2", logo2);
      dataForm.append("logo3", logo3);
      dataForm.append("imgID", catchId);
      dataForm.append("n", excelData?.length);
      dataForm.append("issuerEX", issuerEX);
      dataForm.append("issuerNameEX", issuerNameEX);
      dataForm.append("dataExcel", JSON.stringify(dataExcel));
      // dataForm.append("nameEX", nameEX);
      let upImgs = [];


      await axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/certificate/convertToImage`,
          dataForm,
          {
            headers: {
              // "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${JSON.parse(token)}`,
            },
          }
        )
        .then(async (res) => {
          if (res.status == 200) {
            // console.log(res.data);
            setTempImgs(() => res.data.data);
            for (let i = 0; i < (res.data?.data).length; i++) {
              let blobing = await (await fetch(res.data?.data[i])).blob();
              let file = new File([blobing], "image.jpg", { type: "image/jpeg" });
              upImgs.push(file);
              certificateEX.push(res.data?.data[i]);
            }
            // console.log(upImgs);
            const passingToBlockchain = await mintNFT(upImgs, walletAddressEX);
            // console.log("mera nft passing! ", passingToBlockchain?.ipfsHashes[0], passingToBlockchain?.transactionHash);

            for (let i = 0; i < (res.data?.data).length; i++) {
              ipHashEX.push(passingToBlockchain?.ipfsHashes[i]);

            }

            hashEX = passingToBlockchain?.transactionHash;

            const secondData = {
              emailEX,
              nameEX,
              courseNameEX,
              serialNoEX,
              issuesEX,
              dateOfCompletionEX,
              validityEX,
              isInFolderEX,
              folderEX,
              isSubEX,
              folderIdEX,
              hashEX,
              ipHashEX,
              issuerEX,
              issuerNameEX,
              certificateEX,
              qrEX,
              n,
              catchId
            };

            await axios
              .post(
                `${process.env.REACT_APP_BACKEND_URL}/api/v1/certificate/createDataWithExcel`,
                secondData,
                {
                  headers: {
                    "Content-Type": "Application/json",
                    Authorization: `Bearer ${JSON.parse(token)}`,
                  },
                }
              )
              .then(async (res) => {
                if (res.status == 200) {
                  // toast.success("Successfully uploaded all!");

                  await reChangeCerImages(ipHashEX, hashEX, nameEX, courseNameEX, dateOfCompletionImgEX, excelData, certificateEX, issuerEX, issuerNameEX, emailEX, logo1, logo2, logo3, validityImgEX, serialNoEX, issuesEX);

                  emailEX = [];
                  nameEX = [];
                  courseNameEX = [];
                  dateOfCompletionEX = [];
                  validityEX = [];
                  dateOfCompletionImgEX = [];
                  validityImgEX = [];
                  walletAddressEX = [];
                  isInFolderEX = "";
                  folderEX = "";
                  isSubEX = "";
                  folderIdEX = "";
                  hashEX = "";
                  ipHashEX = [];
                  issuerEX = "";
                  issuerNameEX = "";
                  certificateEX = [];
                  n = null;
                  logo1 = "";
                  logo2 = "";
                  logo3 = "";
                  qrEX = [];
                  e.target.reset();
                  setName("");
                  setDetails("");
                  setSig1("");
                  setSig2("");
                  setLogo("");
                  setDateCom("");
                  setDateValid("");
                  setDate("");
                  setDate2("");
                  setUserRefetch(!userRefetch);
                  // setFolderStructure({ isInFolder: false, folder: null, isSub: false, folderId: null });
                  // let info = { folder: folder, number: excelData?.length, sub: "Certificate" };
                  // localStorage.setItem("Info", JSON.stringify(info));
                  // navigate("/dashboard/dashboard-upload-confirmation");


                }
              })
              .catch((e) => {
                console.log(e);
                toast.error(`${e?.response?.data?.message}`);
              });
          }
        })
        .catch((e) => {
          console.log(e);
          toast.error(`${e?.response?.data?.message}`);
        })
        .finally(() => {
          setLoading(false);
        });

      setLoading(false);
    }
    else {
      setIsWarn(false);
      setLoading(false);
      toast.error("Certificate Count Limit Exceeded");
    }

  };

  const subCer = async (e) => {
    e.preventDefault();
    if (user?.types === "individual") {
      return toast.error("Individual users are not allowed to issue!")
    }
    if (email === user?.email) {
      return toast.error("Use different mail Id");
    }
    setIsWarn(true);
    setLoading(true);
    // generateQR();
    const token = CryptoJS.AES.decrypt(
      localStorage.getItem("userToken"),
      process.env.REACT_APP_MY_SECRET_KYE
    ).toString(CryptoJS.enc.Utf8);


    let userSub;
    let userSub1 = (user?.type_of_subscription?.name).find(data => data[data.length - 2] == 'C');
    let userSub2 = (user?.type_of_subscription?.name).find(data => data == "basic");
    if (!userSub1 && !userSub2) {
      return 'Your subscription is not available';
    }
    userSub = userSub1 ? userSub1 : userSub2;

    let purchasedDateOrigin = (user?.type_of_subscription?.purchasedDate).find(data => data?.packageName === userSub);
    const givenDate = new Date(purchasedDateOrigin?.date);
    const currentDate = new Date();

    const givenYear = givenDate.getFullYear();
    const givenMonth = givenDate.getMonth();
    const givenDay = givenDate.getDate();

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    const oneYearAfterGivenDate = new Date(givenYear + 1, givenMonth, givenDay);
    const oneMonthAfterGivenDate = new Date(givenYear, givenMonth + 1, givenDay);
    let lastChar = userSub[userSub.length - 1];

    const tracks = user?.type_of_subscription?.track;
    const currentYearIndex = tracks.findIndex(entry => entry.year === currentYear);
    let subs = (lastChar === 'M') ? oneMonthAfterGivenDate : oneYearAfterGivenDate;
    // console.log(currentYear, givenYear)
    // isExpire?
    if (currentDate >= subs) {
      // console.log("enter");
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/get-expired`, { email: user?.email, packageName: userSub }, {
        headers: {
          "Content-Type": "Application/json",
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      })
        .then(res => {
          // console.log(res.data);
        })
        .catch((e) => {
          console.log(e);
        })
      setIsWarn(false);
      setLoading(false);
      return toast.error("Your subscription has expired due to max");
    }


    if ((user?.type_of_subscription.cer) < maxCer[userSub]) {



      // let dateOfCompletion = e.target.dateOfCompletion.value;
      // let validity = e.target.validity.value;
      // console.log("Folder Check", folderStructure);
      // console.log("sub", img);
      // let data1 = { email: email, name: name, courseName: details, dateOfCompletion: dateOfCompletion, validity: validity, certificate: img };

      // let finalImg = img;
      const data = new FormData();
      data.append("email", email);
      data.append("name", name);

      data.append("courseName", details);
      data.append("serial", serial);
      data.append("dateOfCompletion", dateCom);
      data.append("validity", dateValid);
      data.append("issueDate", dateIssue);

      data.append("isInFolder", isInFolder);
      data.append("folder", folder);
      data.append("isSub", isSub);
      data.append("folderId", folderId);

      setWeb3Info({
        IPFS: null,
        tHash: null,
        TId: null,
      });
      const imgTake = await exportAsImage();
      // console.log(imgTake);
      const passingToBlockchain = await mintNFT([imgTake], [walletAddress]);

      if (passingToBlockchain && passingToBlockchain != null) {
        setWeb3Info({
          IPFS: passingToBlockchain?.ipfsHashes,
          tHash: passingToBlockchain?.transactionHash,
          TId: null,
        });
        // console.log(
        //   "getting after call",
        //   passingToBlockchain?.ipfsHashes[0],
        //   passingToBlockchain?.transactionHash
        // );

        const generating = await generateQR(
          passingToBlockchain?.ipfsHashes[0],
          passingToBlockchain?.transactionHash
        );
        setElements({
          name: name,
          tHash: passingToBlockchain?.transactionHash,
          ipHash: passingToBlockchain?.ipfsHashes,
          qr: generating,
        });
        // await exportAsImage();
        data.append("hash", passingToBlockchain?.transactionHash);
        data.append("ipHash", passingToBlockchain?.ipfsHashes[0]);
        data.append("issuer", user?.email);
        data.append("issuerName", user?.org_individual_Name ? user?.org_individual_Name : "");
        // data.append("certificate", img);
        data.append("imgID", catchId);
        data.append("qrCode", generating);
        data.append("logo1", sig1?.origin);
        data.append("logo2", logo?.origin);
        data.append("logo3", sig2?.origin);

        // console.log(src);
        // console.log(img);
        await axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/certificate/create-certificate`,
            data,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                authorization: `Bearer ${JSON.parse(token)}`,
              },
            }
          )
          .then((res) => {
            if (res.status == 200) {
              toast.success("Successfully Uploaded");
              // console.log(res.data);
              e.target.reset();
              setName("");
              setDetails("");
              setSig1("");
              setSig2("");
              setLogo("");
              setDateCom("");
              setDateValid("");
              setDate("");
              setDate2("");
              setLoading(false);
              // console.log("id", res.data?.data?._id);
              // localStorage.getItem("instantID")
              localStorage.setItem("instantID", res.data?.data?._id);
              // setInstantID(() => res.data?.data?._id);
              let info = { folder: folder, number: excelData?.length, sub: "Certificate" };
              localStorage.setItem("Info", JSON.stringify(info));
              setDetailsCardShow(true);
              setFolderStructure({ isInFolder: false, folder: null, isSub: false, folderId: null });
              navigate(`/dashboard/dashboard-upload-details/${res.data?.data?._id}`);
              setUserRefetch(!userRefetch);

              // handleClickOpen();
            } else {
              // console.log("errors from while saving");
              toast.error("Something is wrong");
            }
          })
          .catch((e) => {
            console.log(e);
            toast.error(`${e?.response?.data?.message}`);

          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        toast.error("Something is wrong");
        // console.log(
        //   passingToBlockchain?.ipfsHashes,
        //   passingToBlockchain?.transactionHash
        // );
        // console.log("errors from while saving2");
      }
      setLoading(false);
    }
    else {
      setIsWarn(false);
      setLoading(false);
      toast.error("Certificate Count Limit Exceeded");
    }
  };
  // console.log("Open", detailsCardShow)
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <input class="datePickerClass" onClick={onClick} ref={ref}>
      {value}
    </input>
  ));
  return (
    <section className="mb-5">
      {
        loading ? <Gifloader name={"Certificate"} /> :
          <div className="container mt-3 mb-5">
            <div
              className="d-flex justify-content-start align-items-center"
              style={{ gap: "15px" }}
            >
              <ArrowBackIcon
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              />
              <h4 className="dTitle">Certificate</h4>
            </div>
            {/* <button onClick={() => test()}>test</button> */}
            <p
              style={{
                color: "#545454",
                fontFamily: "Saira",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                whiteSpace: "2px"
              }}
              className="ps-4 ms-3"
            >
              choose your fonts and colors and fill details to preview certificate
            </p>
            {/* <p className='pt-2 text-secondary'>My Folder /Unitic services</p> */}

            {detailsCardShow ? <DashboardShowUserCard setDetailsCardShow={setDetailsCardShow} detailsCardShow={detailsCardShow} /> : <>
              <div className={`${bulkChecking == false ? "d-none" : "d-block"}`}>
                <div className="widthHandle mx-auto mt-5 mb-4">
                <div
                    className={`w-100 box-cer box-cer-A4-${imgData?.id}`}
                  >
                    <img src={`${imgData?.temp}`} alt="" className="img-fluid" />
                    <img
                      src={src}
                      alt="logo"
                      className={`${imgData?.id == 5
                        ? `handleQrCodeV3CT${imgData?.id}`
                        : "handleQrCodeV2"
                        } img-fluid handleQrCodeV4CT${imgData?.id}`}
                      width={80}
                    />
                    <div
                      className={`handleTextCerV1CT${imgData?.id}`}
                      style={{ width: imgData?.id == 15 ? "50%" : imgData?.id != 13 ? "55%" : "74%" }}
                    >
                      <h3
                        className={`handleNameCerV3 handleNameCerV3Definite${imgData?.id}`}
                        style={{ fontFamily: imgData?.id == 12 ? "'Great Vibes', cursive" : imgData?.id == 14 ? "'Sedan SC', sans-serif" : (imgData?.id == 15 || imgData?.id == 16) ? "'Oleo Script', system-ui" : "'Playball', cursive", fontWeight: imgData?.id == 12 ? 600 : imgData?.id == 14 ? 600 : 500 }}
                        dangerouslySetInnerHTML={{
                          __html: draftToHtml(
                            convertToRaw(nameRich.getCurrentContent())
                          ),
                        }}
                      ></h3>
                      {(imgData?.id != 14 && imgData?.id != 15) && <p
                        className={`mb-0 handleDetailsCerV3 handleDetailsCerV3Definite${imgData?.id}`}
                        dangerouslySetInnerHTML={{
                          __html: draftToHtml(
                            convertToRaw(detailsRich.getCurrentContent())
                          ),
                        }}
                      ></p>}
                      {imgData?.id == 3 || imgData?.id == 4 || imgData?.id == 5 || imgData?.id == 8 || imgData?.id == 11 || imgData?.id == 12 || imgData?.id == 13 || imgData?.id == 15 || imgData?.id == 16 ? (
                        <></>
                      ) : (
                        <p className={`mb-0 handleDetailsDateV3 handleDetailsDateV3Definite${imgData?.id}`}>{dateCom}</p>
                      )}

                      {/* {console.log(imgData?.id)} */}
                    </div>
                    {imgData?.id == 13 && (
                      <p
                        className={`mb-0 handleDetailsDateV3 handleSerial${imgData?.id}`}
                      >
                        {serial}
                      </p>
                    )}
                    {(imgData?.id == 3 || imgData?.id == 4 || imgData?.id == 5 || imgData?.id == 8 || imgData?.id == 11 || imgData?.id == 12 || imgData?.id == 13 || imgData?.id == 15 || imgData?.id == 16) && (
                      <p
                        className={`mb-0 handleDetailsDateV3 handleDetailsDateDefinite${imgData?.id}`}
                      >
                        {dateCom}
                      </p>
                    )}
                    {(imgData?.id == 12 || imgData?.id == 13 || imgData?.id == 15 || imgData?.id == 16) && (
                      <p
                        className={`mb-0 handleDetailsDateV3 handleDetailsDateDefiniteV2${imgData?.id}`}
                      >
                        {dateValid}
                      </p>
                    )}
                    {imgData?.id == 13 && (
                      <p
                        className={`mb-0 handleDetailsDateV3 handleDetailsDateDefiniteV3${imgData?.id}`}
                      >
                        {dateIssue}
                      </p>
                    )}

                    {imgData?.id == 3 || imgData?.id == 4 || imgData?.id == 5 || imgData?.id == 6 || imgData?.id == 7 || imgData?.id == 8 || imgData?.id == 11 || imgData?.id == 12 || imgData?.id == 13 || imgData?.id == 14 || imgData?.id == 15 || imgData?.id == 16 ? (
                      <></>
                    ) : (
                      <div className={`handleImgCerV3CT${imgData?.id}`}>
                        <div className="w-100 h-100 text-center">
                          <img src={sig1?.show} alt="" className="" />
                        </div>
                        <div className={`handleLog${imgData?.id} h-100 text-center`}>
                          <img
                            src={logo?.show}
                            alt=""
                            className="h-100 rounded-circle"
                          />{" "}
                        </div>
                        <div className="w-100 h-100 text-center">
                          <img src={sig2?.show} alt="" className="" />
                        </div>
                      </div>
                    )}

                    {(imgData?.id == 3 || imgData?.id == 4 || imgData?.id == 5 || imgData?.id == 6 || imgData?.id == 7 || imgData?.id == 8 || imgData?.id == 11 || imgData?.id == 13) && (
                      <div className={`handleImgCerV4CT${imgData?.id}`}>
                        <img
                          src={logo?.show}
                          alt=""
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                            maxHeight: "100%",
                          }}
                          className="rounded-circle"
                        />
                      </div>
                    )}
                    {(imgData?.id == 3 || imgData?.id == 4 || imgData?.id == 5 || imgData?.id == 5 || imgData?.id == 8 || imgData?.id == 11) && (
                      <div className={`handleImgCerV3CT${imgData?.id}`}>
                        {/* <div className='w-100 h-100 text-center handleDiffLogo'> <img src={logo?.show} alt="" style={{ maxWidth: '100%', height: '100%' }} className=' rounded-circle' /> </div> */}
                        <img
                          src={sig1?.show}
                          alt=""
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                            maxHeight: "100%",
                          }}
                          className=""
                        />
                      </div>
                    )}
                    {(imgData?.id == 6 || imgData?.id == 7 || imgData?.id == 12 || imgData?.id == 16) && (
                      <div className={`handleImgCerV3CT${imgData?.id}`}>
                        {/* <div className='w-100 h-100 text-center handleDiffLogo'> <img src={logo?.show} alt="" style={{ maxWidth: '100%', height: '100%' }} className=' rounded-circle' /> </div> */}
                        <div className="w-100 h-100 text-center">
                          <img
                            src={sig1?.show}
                            alt=""
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              maxHeight: "100%",
                            }}
                            className=""
                          />
                        </div>
                        <div className="w-100 h-100 text-center">
                          <img
                            src={sig2?.show}
                            alt=""
                            className=""
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              maxHeight: "100%",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <div className="handleDownloadOfferSheet px-2 px-lg-0">
                    <h5>Download our Excel Template fill Details and upload Again</h5>
                    <a
                      href={`${imgData?.id == 12 ? '/assets/msOffice/sampleOrigin12.xlsx' : imgData?.id == 13 ? '/assets/msOffice/sampleOrigin13.xlsx' : imgData?.id == 14 ? '/assets/msOffice/sampleOrigin14.xlsx' : imgData?.id == 15 ? '/assets/msOffice/sampleOrigin15.xlsx' : imgData?.id == 16 ? '/assets/msOffice/sampleOrigin16.xlsx' : '/assets/msOffice/sampleOrigin.xlsx'}`}
                      download={true}
                      className="mb-0 pt-1 downloadText"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FileDownloadOutlinedIcon /> Template Excel File
                    </a>
                  </div>
                  <div
                    className={`mx-auto text-center mt-3 px-2 px-lg-0 handleExcelPart`}
                  >
                    <form
                      className="form-group custom-form mx-auto text-center"
                      onSubmit={handleFileSubmit}
                    >
                      <h5>Upload your excel sheet</h5>
                      <input
                        type="file"
                        className="form-control mt-1 handleScanWidth"
                        style={{ border: typeError && "1px solid #dd3c3c" }}
                        required
                        onChange={handleFile}
                      />{" "}
                      <br />
                      <p className="mb-0">
                        <small>{typeError}</small>
                      </p>
                      <Button
                        variant="success"
                        className="mt-2"
                        type="submit"
                        disabled={typeError}
                      >
                        Upload Excel
                      </Button>
                      {/* {typeError && (
                                    <>
                                        <br />
                                        <Stack className='handleAlertWidth  my-2 text-center mx-auto' spacing={2}>
                                            <Alert severity="error" >{typeError}</Alert>
                                        </Stack>

                                    </>
                                )} */}
                    </form>
                  </div>
                </div>
              </div>

              <div
                className={`${bulkChecking == true ? "d-none" : "d-flex"
                  }  justify-content-between align-items-start mt-2 mx-auto flex-column flex-lg-row gapping`}
              >
                <div className="mx-auto w-100">
                  <p className="text-center titleCr">
                    Please Fill the details for Certificate
                  </p>

                  <div className="form-issue mt-4 mb-0 mb-lg-5" style={{ width: '90%' }}>
                    <form onSubmit={excelData?.length > 1 ? subCerMulti : subCer}>
                      <label>Receiver Email-Id <span className="text-danger fs-5">*</span></label> <br />
                      <input
                        type="email"
                        name="email"
                        required
                        value={email}
                        onBlur={(e) => validEmail(e)}
                        onChange={(e) => setEmail(e.target.value)}
                        readOnly={isBulk == "true"}
                        style={{ border: (email.length > 0 || isWarn) ? "" : "1px solid #dd3c3c" }}
                      />{" "}
                      <br />
                      <label>Name <span className="text-danger fs-5">*</span></label> <br />
                      <Editor
                        toolbarOnFocus
                        onFocus={(e) => {
                          setIsFocusName(() => true);
                        }}
                        onBlur={(e) => {
                          setIsFocusName(() => false);
                        }}
                        editorState={nameRich}
                        onEditorStateChange={(e) => handleInputChangeName(e)}
                        handleBeforeInput={handleBeforeInputName}
                        handleReturn={handleReturn}
                        handlePastedText={handlePastedText}
                        handleKeyCommand={handleKeyDown}
                        handleBeforeTextPaste={handleBeforeTextPaste}
                        handlePaste={handlePaste}
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        editorStyle={{
                          height: "45px",
                          borderRadius: "42px",
                          paddingLeft: "10px",
                          border: (nameRich.getCurrentContent().getPlainText("").length > 0 || isWarn) ? "" : "1px solid #dd3c3c"
                        }}

                        toolbarClassName="toolbarClassName text-black"
                        toolbarStyle={{
                          display: isFocusName ? "flex" : "none",
                          left: `${contentWidth + 30}px`,
                        }}
                        toolbar={{
                          options: ["fontFamily", "colorPicker", "inline"],

                          fontFamily: {
                            options: ["Arial", "Georgia", "Impact", "Tahoma", "Times New Roman", "Verdana", "Courier New", "Playball", "Great Vibes"
                            ],
                          },
                          colorPicker: {
                            colors: ["rgb(0, 0, 139)", "rgb(97,189,109)", "rgb(26,188,156)", "rgb(84,172,210)", "rgb(44,130,201)", "rgb(147,101,184)", "rgb(71,85,119)", "rgb(204,204,204)", "rgb(65,168,95)", "rgb(0,168,133)", "rgb(61,142,185)", "rgb(41,105,176)", "rgb(85,57,130)", "rgb(40,50,78)", "rgb(0,0,0)", "rgb(247,218,100)", "rgb(251,160,38)", "rgb(235,107,86)", "rgb(226,80,65)", "rgb(163,143,132)", "rgb(239,239,239)", "rgb(255,255,255)", "rgb(250,197,28)", "rgb(243,121,52)", "rgb(209,72,65)", "rgb(184,49,47)", "rgb(124,112,107)", "rgb(209,213,216)",
                            ], // Example of custom colors
                          },
                          inline: {
                            options: ["bold", "italic", "underline"],
                          },
                        }}
                      />
                      {(imgData?.id != 14 && imgData?.id != 15) && <>
                        <label className="mt-3">Course Name <span className="text-danger fs-5">*</span></label> <br />
                        <Editor
                          toolbarOnFocus
                          onFocus={(e) => {
                            setIsFocusDetails(() => true);
                          }}
                          onBlur={(e) => {
                            setIsFocusDetails(() => false);
                          }}
                          editorState={detailsRich}
                          onEditorStateChange={(e) => handleInputChangeDetails(e)}
                          handleBeforeInput={handleBeforeInput}
                          handleReturn={handleReturn}
                          handlePastedText={handlePastedText}
                          handleKeyCommand={handleKeyDown}
                          handleBeforeTextPaste={handleBeforeTextPaste}
                          handlePaste={handlePaste}
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          toolbarClassName="toolbarClassName text-black"
                          editorStyle={{
                            borderRadius: "36px",
                            height: "64px",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                            border: (detailsRich.getCurrentContent().getPlainText("").length > 0 || isWarn) ? "" : "1px solid #dd3c3c"
                          }}
                          toolbarStyle={{
                            display: isFocusDetails ? "flex" : "none",
                            left: `${contentWidthD + 30}px`,
                          }}
                          toolbar={{
                            options: ["fontFamily", "colorPicker", "inline"],

                            fontFamily: {
                              options: ["Arial", "Georgia", "Impact", "Tahoma", "Times New Roman", "Verdana", "Courier New", "Playball", "Great Vibes"
                              ],
                            },
                            colorPicker: {
                              colors: ["rgb(97,189,109)", "rgb(26,188,156)", "rgb(84,172,210)", "rgb(44,130,201)", "rgb(147,101,184)", "rgb(71,85,119)", "rgb(204,204,204)", "rgb(65,168,95)", "rgb(0,168,133)", "rgb(61,142,185)", "rgb(41,105,176)", "rgb(85,57,130)", "rgb(40,50,78)", "rgb(0,0,0)", "rgb(247,218,100)", "rgb(251,160,38)", "rgb(235,107,86)", "rgb(226,80,65)", "rgb(163,143,132)", "rgb(239,239,239)", "rgb(255,255,255)", "rgb(250,197,28)", "rgb(243,121,52)", "rgb(209,72,65)", "rgb(184,49,47)", "rgb(124,112,107)", "rgb(209,213,216)",
                              ], // Example of custom colors
                            },
                            inline: {
                              options: ["bold", "italic", "underline"],
                            },
                          }}
                        />
                      </>}
                      {/* <input type="text" name="courseName" value={details} onChange={handleInputChangeDetails} required /> <br /> */}
                      {imgData?.id == 13 && <><label className="mt-3 pt-1">Certificate No. <span className="text-danger fs-5">*</span></label> <br />
                        <input
                          type="text"
                          name="serial"
                          required
                          value={serial}
                          onChange={(e) => {
                            setSerial(e.target.value);
                            // generateQR();
                          }}
                          readOnly={isBulk == "true"}
                          style={{ border: (serial.length > 0 || isWarn) ? "" : "1px solid #dd3c3c", cursor: 'pointer' }}
                        />{" "}
                        <br /></>}

                      <label className="mt-1 pt-1">{(imgData?.id == 12 || imgData?.id == 13 || imgData?.id == 15 || imgData?.id == 16) ? 'From Date' : 'Date of Completion'} <span className="text-danger fs-5">*</span></label> <br />
                      <DatePicker
                        type="date"
                        selected={date}
                        autoComplete="off"
                        onChange={(date) => {
                          fixingDateCom(date); // Pass the date directly to fixingDateCom
                          // generateQR();
                        }}

                        placeholderText="DD/MM/YYYY" // Placeholder for the date picker
                        dateFormat="dd/MM/yyyy" // Format for the displayed date
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        name="dateOfCompletion"
                        className="datePickerClass"
                        ref={inputDate1}
                        required
                        onClick={() => inputDate1.current.showPicker()}
                        readOnly={isBulk == "true"}
                        style={{ border: (date.length > 0 || isWarn) ? "" : "1px solid #dd3c3c", cursor: 'pointer' }}
                      // Add any other necessary props or configurations here
                      />
                      {" "}
                      <br />
                      {imgData?.id != 14 && <>
                        <label className="mt-1 pt-1">{(imgData?.id == 12 || imgData?.id == 13 || imgData?.id == 15 || imgData?.id == 16) ? 'To Date' : 'Validity'} <span className="text-danger fs-5">*</span></label> <br />
                        <DatePicker
                          autoComplete="off"
                          type="date"
                          className="datePickerClass"
                          name="validity"
                          ref={inputDate2}
                          placeholderText="DD/MM/YYYY" // Placeholder for the date picker
                          dateFormat="dd/MM/yyyy" // Format for the displayed date
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          required
                          selected={date2}
                          onChange={(e) => fixingDateValid(e)}
                          onClick={() => inputDate2.current.showPicker()}
                          readOnly={isBulk == "true"}
                          style={{ border: (date2.length > 0 || isWarn) ? "" : "1px solid #dd3c3c", cursor: 'pointer' }}
                        />{" "}
                        <br />
                      </>}
                      {imgData?.id == 13 && <> <label className="mt-1 pt-1">Issue Date <span className="text-danger fs-5">*</span></label> <br />
                        <DatePicker
                          autoComplete="off"
                          type="date"
                          name="issueDate"
                          ref={inputDate3}
                          className="datePickerClass"
                          required
                          selected={date3}
                          onChange={(e) => fixingDateIssue(e)}
                          placeholderText="DD/MM/YYYY" // Placeholder for the date picker
                          dateFormat="dd/MM/yyyy" // Format for the displayed date
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          onClick={() => inputDate3.current.showPicker()}
                          readOnly={isBulk == "true"}
                          style={{ border: (date3.length > 0 || isWarn) ? "" : "1px solid #dd3c3c", cursor: 'pointer' }}
                        />{" "}
                        <br /> </>}
                      {(imgData?.id != 13 && imgData?.id != 14 && imgData?.id != 15) ? <>
                        <label className="mt-1 pt-1">Upload your Signature <span className="text-danger fs-5">*</span></label>{" "}
                        <br />
                        <div className="input-groupCus" style={{ border: (sig1.origin?.name.length > 0 || isWarn) ? "" : "1px solid #dd3c3c" }}>
                          <input
                            type="text"
                            readOnly
                            style={{ cursor: 'pointer' }}
                            class="form-control"
                            id="inputGroupFile0x"
                            name="demo"
                            value={sig1.origin?.name}
                            onClick={() => handleLabelClick1()}

                          />
                          <input
                            type="file"
                            class="form-control"
                            id="inputGroupFile01"
                            name="signature"
                            ref={fileInputRef1}
                            onChange={(e) => {
                              setSig1({
                                origin: e.target.files[0],
                                show: URL.createObjectURL(e.target.files[0]),
                              });
                              // exportAsImage();
                            }}
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label
                            class="input-group-textCus"
                            onClick={() => handleLabelClick1()}
                          >
                            Upload
                          </label>
                        </div>
                        {imgData?.id == 3 ||
                          imgData?.id == 4 ||
                          imgData?.id == 5 ||
                          imgData?.id == 8 ||
                          imgData?.id == 11 || imgData?.id == 12 ? (
                          <></>
                        ) : (
                          <>
                            {" "}
                            <label className="mt-1 pt-1">
                              Upload your Signature2 <span className="text-danger fs-5">*</span>
                            </label>{" "}
                            <br />
                            <div className="input-groupCus" style={{ border: (sig2.origin?.name.length > 0 || isWarn) ? "" : "1px solid #dd3c3c" }}>
                              <input
                                type="text"
                                readOnly
                                style={{ cursor: 'pointer' }}
                                class="form-control"
                                id="inputGroupFile0xx"
                                name="demo"
                                value={sig2.origin?.name}
                                onClick={() => handleLabelClick2()}

                              />
                              <input
                                type="file"
                                class="form-control"
                                id="inputGroupFile02"
                                name="signature2"
                                ref={fileInputRef2}
                                onChange={(e) => {
                                  setSig2({
                                    origin: e.target.files[0],
                                    show: URL.createObjectURL(e.target.files[0]),
                                  });
                                  // exportAsImage();
                                }}

                                accept="image/*"
                                style={{ display: "none" }}
                              />
                              <label
                                class="input-group-textCus"
                                onClick={() => handleLabelClick2()}
                              >
                                Upload
                              </label>
                            </div>{" "}
                          </>
                        )}
                      </> : null}
                      {
                        (imgData?.id == 12 || imgData?.id == 14 || imgData?.id == 15 || imgData?.id == 16) ? null : <> {(imgData?.id == 13 && excelData?.length > 1) ? null : <>
                          <label className="mt-1 pt-1">Upload your {imgData?.id == 13 ? 'photo' : 'logo'} {imgData?.id != 13 ? <span className="text-danger fs-5">*</span> : null}</label> <br />
                          <div className="input-groupCus" style={{ border: (logo.origin?.name.length > 0 || isWarn) ? "" : "1px solid #dd3c3c" }}>
                            <input
                              type="text"
                              readOnly
                              style={{ cursor: 'pointer' }}
                              class="form-control"
                              id="inputGroupFile0xxxx"
                              name="demo"
                              value={logo.origin?.name}
                              onClick={() => handleLabelClick3()}
                            />
                            <input
                              type="file"
                              class="form-control"
                              id="inputGroupFile03"
                              name="logo"
                              ref={fileInputRef3}
                              onChange={(e) => {
                                setLogo({
                                  origin: e.target.files[0],
                                  show: URL.createObjectURL(e.target.files[0]),
                                });
                                // exportAsImage();
                              }}
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label
                              class="input-group-textCus"
                              onClick={() => handleLabelClick3()}
                            >
                              Upload
                            </label>
                          </div>
                        </>}</>
                      }

                      <div className="text-center titleCr mt-3 mb-2">

                        <Button
                          variant="warning"
                          className="text-global px-4 py-2 rounded-pill"
                          type="submit"
                          onClick={() => setIsWarn(false)}
                        >
                          Upload on Blockchain
                        </Button>

                      </div>
                    </form>
                  </div>
                </div>

                {/* Main template are showing from here  */}
                <div className="mx-auto mt-0 mt-lg-3 w-100">
                  <div
                    className={`w-100 box-cer box-cer-A4-${imgData?.id}`}
                    id="cerToSVG"
                    ref={certificateTemplate}
                  >
                    <img src={`${imgData?.temp}`} alt="" className="img-fluid" />
                    <img
                      src={src}
                      alt="logo"
                      className={`${imgData?.id == 5
                        ? `handleQrCodeV3CT${imgData?.id}`
                        : "handleQrCodeV2"
                        } img-fluid handleQrCodeV4CT${imgData?.id}`}
                      width={80}
                    />
                    <div
                      className={`handleTextCerV1CT${imgData?.id}`}
                      style={{ width: imgData?.id == 15 ? "50%" : imgData?.id != 13 ? "55%" : "74%" }}
                    >
                      <h3
                        className={`handleNameCerV3 handleNameCerV3Definite${imgData?.id}`}
                        style={{ fontFamily: imgData?.id == 12 ? "'Great Vibes', cursive" : imgData?.id == 14 ? "'Sedan SC', sans-serif" : (imgData?.id == 15 || imgData?.id == 16) ? "'Oleo Script', system-ui" : "'Playball', cursive", fontWeight: imgData?.id == 12 ? 600 : imgData?.id == 14 ? 600 : 500 }}
                        dangerouslySetInnerHTML={{
                          __html: draftToHtml(
                            convertToRaw(nameRich.getCurrentContent())
                          ),
                        }}
                      ></h3>
                      {(imgData?.id != 14 && imgData?.id != 15) && <p
                        className={`mb-0 handleDetailsCerV3 handleDetailsCerV3Definite${imgData?.id}`}
                        dangerouslySetInnerHTML={{
                          __html: draftToHtml(
                            convertToRaw(detailsRich.getCurrentContent())
                          ),
                        }}
                      ></p>}
                      {imgData?.id == 3 || imgData?.id == 4 || imgData?.id == 5 || imgData?.id == 8 || imgData?.id == 11 || imgData?.id == 12 || imgData?.id == 13 || imgData?.id == 15 || imgData?.id == 16 ? (
                        <></>
                      ) : (
                        <p className={`mb-0 handleDetailsDateV3 handleDetailsDateV3Definite${imgData?.id}`}>{dateCom}</p>
                      )}

                      {/* {console.log(imgData?.id)} */}
                    </div>
                    {imgData?.id == 13 && (
                      <p
                        className={`mb-0 handleDetailsDateV3 handleSerial${imgData?.id}`}
                      >
                        {serial}
                      </p>
                    )}
                    {(imgData?.id == 3 || imgData?.id == 4 || imgData?.id == 5 || imgData?.id == 8 || imgData?.id == 11 || imgData?.id == 12 || imgData?.id == 13 || imgData?.id == 15 || imgData?.id == 16) && (
                      <p
                        className={`mb-0 handleDetailsDateV3 handleDetailsDateDefinite${imgData?.id}`}
                      >
                        {dateCom}
                      </p>
                    )}
                    {(imgData?.id == 12 || imgData?.id == 13 || imgData?.id == 15 || imgData?.id == 16) && (
                      <p
                        className={`mb-0 handleDetailsDateV3 handleDetailsDateDefiniteV2${imgData?.id}`}
                      >
                        {dateValid}
                      </p>
                    )}
                    {imgData?.id == 13 && (
                      <p
                        className={`mb-0 handleDetailsDateV3 handleDetailsDateDefiniteV3${imgData?.id}`}
                      >
                        {dateIssue}
                      </p>
                    )}

                    {imgData?.id == 3 || imgData?.id == 4 || imgData?.id == 5 || imgData?.id == 6 || imgData?.id == 7 || imgData?.id == 8 || imgData?.id == 11 || imgData?.id == 12 || imgData?.id == 13 || imgData?.id == 14 || imgData?.id == 15 || imgData?.id == 16 ? (
                      <></>
                    ) : (
                      <div className={`handleImgCerV3CT${imgData?.id}`}>
                        <div className="w-100 h-100 text-center">
                          <img src={sig1?.show} alt="" className="" />
                        </div>
                        <div className={`handleLog${imgData?.id} h-100 text-center`}>
                          <img
                            src={logo?.show}
                            alt=""
                            className="h-100 rounded-circle"
                          />{" "}
                        </div>
                        <div className="w-100 h-100 text-center">
                          <img src={sig2?.show} alt="" className="" />
                        </div>
                      </div>
                    )}

                    {(imgData?.id == 3 || imgData?.id == 4 || imgData?.id == 5 || imgData?.id == 6 || imgData?.id == 7 || imgData?.id == 8 || imgData?.id == 11 || imgData?.id == 13) && (
                      <div className={`handleImgCerV4CT${imgData?.id}`}>
                        <img
                          src={logo?.show}
                          alt=""
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                            maxHeight: "100%",
                          }}
                          className="rounded-circle"
                        />
                      </div>
                    )}
                    {(imgData?.id == 3 || imgData?.id == 4 || imgData?.id == 5 || imgData?.id == 5 || imgData?.id == 8 || imgData?.id == 11) && (
                      <div className={`handleImgCerV3CT${imgData?.id}`}>
                        {/* <div className='w-100 h-100 text-center handleDiffLogo'> <img src={logo?.show} alt="" style={{ maxWidth: '100%', height: '100%' }} className=' rounded-circle' /> </div> */}
                        <img
                          src={sig1?.show}
                          alt=""
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                            maxHeight: "100%",
                          }}
                          className=""
                        />
                      </div>
                    )}
                    {(imgData?.id == 6 || imgData?.id == 7 || imgData?.id == 12 || imgData?.id == 16) && (
                      <div className={`handleImgCerV3CT${imgData?.id}`}>
                        {/* <div className='w-100 h-100 text-center handleDiffLogo'> <img src={logo?.show} alt="" style={{ maxWidth: '100%', height: '100%' }} className=' rounded-circle' /> </div> */}
                        <div className="w-100 h-100 text-center">
                          <img
                            src={sig1?.show}
                            alt=""
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              maxHeight: "100%",
                            }}
                            className=""
                          />
                        </div>
                        <div className="w-100 h-100 text-center">
                          <img
                            src={sig2?.show}
                            alt=""
                            className=""
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              maxHeight: "100%",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>


              {open && (
                <ShowUser
                  open={open}
                  setOpen={setOpen}
                  handleClickOpen={handleClickOpen}
                  handleClose={handleClose}
                  elements={elements}
                />
              )}
            </>}
          </div>


      }
    </section>
  );
};

export default DashboardCreateCertificate;
