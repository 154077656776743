import "./comingsoon.css";

const ComingSoon = () => {
  return (
    <section style={{height:`${window.innerHeight/1.7}px`,paddingTop:"150px"}}>
      <h1 className="text-center coming-soon-style">Coming Soon....</h1>
    </section>
  );
};
export default ComingSoon;
